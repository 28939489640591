import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ArrowBack, ArrowForward } from '@styleshare/react-icons/line';

import { Swiper } from './Swiper';
import { useSwiper } from './useSwiper';

interface Props {
  children: React.ReactNode;
  /**
   * 한 프레임에 보이는 슬라이드 개수
   */
  slidesPerView: number;
  /**
   * 이전이나 다음으로 넘길 때 스크롤되는 슬라이드 개수
   */
  slidesToScroll?: number;
  /**
   * 슬라이드 사이의 간격 (px)
   */
  gap?: number;
  /**
   * 프레임 오른쪽에 다음 슬라이드를 노출시켜줄 크기 (px)
   */
  peek?: number;
}

export const EventSwiper = ({
  children,
  slidesPerView,
  slidesToScroll = 1,
  gap,
  peek,
}: Props) => {
  const swiper = useSwiper({ gap, peek, slidesPerView });
  const length = useMemo(() => React.Children.count(children), [children]);

  const canScroll = slidesPerView < length;
  const isFirstIndex = swiper.index === 0;
  const isLastIndex = swiper.index === length - slidesPerView;

  return (
    <Wrapper>
      <Swiper {...swiper}>{children}</Swiper>
      {canScroll && (
        <div>
          {!isFirstIndex && (
            <ControlButton
              css="left: 24px;"
              onClick={() => swiper.moveBy(-slidesToScroll)}
            >
              <StyledArrowBack />
            </ControlButton>
          )}
          {!isLastIndex && (
            <ControlButton
              css="right: 24px;"
              onClick={() => swiper.moveBy(slidesToScroll)}
            >
              <StyledArrowForward />
            </ControlButton>
          )}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ControlButton = styled.button`
  position: absolute;
  top: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  padding: 0;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 9999px;
  box-shadow: 0 1px 4px ${({ theme }) => theme.color.black_16};
`;

const caretCss = css`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.color.gray100};
`;

const StyledArrowBack = styled(ArrowBack)`
  ${caretCss}
  margin-right: 10px;
`;

const StyledArrowForward = styled(ArrowForward)`
  ${caretCss}
  margin-left: 10px;
`;
