import { useCallback, useEffect, useRef } from 'react';

import { useIntersectionObserver } from '@styleshare/hooks';

import {
  useAnalyticsEvent,
  UseAnalyticsEventOptions,
  UseAnalyticsEventParams,
} from './useAnalyticsEvent';

export interface UseAnalyticsViewEventParams extends UseAnalyticsEventParams {
  options?: UseAnalyticsViewEventOptions;
}

interface UseAnalyticsViewEventOptions extends UseAnalyticsEventOptions {
  triggerEverytime?: boolean; // 화면에 표시될 때 마다 로깅할 지 유무
  visibilityRatio?: number; // 표시되는 영역. 0~1 (0=0%, 0.5=50%, 1=100%)
  visibilityTime?: number; // 화면 표시 최소 시간
}

/**
 * useAnalyticsViewEvent - 이벤트 뷰를 로깅한다.
 *
 * ### options
 * triggerEverytime?: boolean; - 컴포넌트가 보일 때마다 이벤트 로깅할 지 유무. (intersectionRef binding 필요)
 * visibilityRatio?: number; - 표시되는 영역. 0~1 (0=0%, 0.5=50%, 1=100%)
 * visibilityTime?: number; - 화면 표시 최소 시간 ms
 */
export function useAnalyticsViewEvent<T extends HTMLElement>({
  event,
  parameters,
  options = {},
}: UseAnalyticsViewEventParams) {
  const {
    triggerEverytime = false,
    visibilityRatio = 0.8,
    visibilityTime = 300,
    ...trackOptions
  } = options;

  const track = useAnalyticsEvent({
    event,
    options: trackOptions,
    parameters,
  });

  const executedRef = useRef(false);

  const interval = useRef<ReturnType<typeof setTimeout>>();

  const subscriber = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      if (!entry?.isIntersecting) {
        return;
      }

      interval.current = setInterval(() => {
        const currentVisibillityTime = performance.now() - entry.time;

        if (currentVisibillityTime < visibilityTime) {
          return;
        }

        interval.current && clearInterval(interval.current);

        if (triggerEverytime) {
          track();
          return;
        }
        if (executedRef.current) {
          return;
        }

        track();
        executedRef.current = true;
      }, 100);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerEverytime, visibilityTime, track],
  );

  const [subscribe, unsubcribe] = useIntersectionObserver<T>(subscriber, {
    root: null,
    rootMargin: '0px',
    threshold: visibilityRatio,
  });

  useEffect(() => {
    return () => {
      interval.current && clearInterval(interval.current);
      unsubcribe();
    };
  }, [unsubcribe]);

  return {
    bindAnalyticsViewRef: subscribe,
    track,
  };
}
