import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  className?: string;
  href?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * @see {@link https://zpl.io/VkqvJ0q}
 */
export function RoundedTextChip({
  children,
  className,
  href,
  icon,
  onClick,
}: Props) {
  return (
    <StyledChip
      className={className}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      onClick={onClick}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {children}
    </StyledChip>
  );
}

const StyledChip = styled.a`
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 5px 11px 4px 9px;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.s14};
  background-color: ${({ theme }) => theme.color.gray100_50};
  border-radius: ${({ theme }) => theme.radius.chip};
`;
const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  font-size: ${({ theme }) => theme.fontSize.s16};
`;
