import mod from '../../utils/math/mod';

export interface Slide {
  xStart: number;
  xEnd: number;
  width: number;
}

export const makeSlides = (slideWidths: number[], gap: number): Slide[] => {
  if (slideWidths.length === 0) {
    return [];
  }

  const initalValue: Slide[] = [
    {
      width: slideWidths[0],
      xEnd: slideWidths[0],
      xStart: 0,
    },
  ];

  const slideRects = slideWidths
    .slice(1, slideWidths.length)
    .reduce((prev, curr) => {
      const xStart = prev[prev.length - 1].xEnd + gap;
      const xEnd = xStart + curr;

      return [
        ...prev,
        {
          width: curr,
          xEnd,
          xStart,
        },
      ];
    }, initalValue);

  return slideRects;
};

export const findRawSlide = (
  slides: Slide[],
  gap: number,
  rawIndex: number,
): Slide => {
  if (slides.length === 0) {
    return { width: 0, xEnd: 0, xStart: 0 };
  }

  const rawIndexMod = mod(rawIndex, slides.length);
  const rawIndexRemainder = Math.floor(rawIndex / slides.length);

  const rawSlide: Slide = {
    width: slides[rawIndexMod].width,
    xEnd:
      slides[rawIndexMod].xStart +
      slides[rawIndexMod].width +
      slides[slides.length - 1].xEnd * rawIndexRemainder +
      gap,
    xStart:
      slides[rawIndexMod].xStart +
      (slides[slides.length - 1].xEnd + gap) * rawIndexRemainder,
  };

  return rawSlide;
};

export const findSlideIndex = (slides: Slide[], x: number) =>
  slides.reduce(
    (prev, _, index) =>
      Math.abs(slides[index].xStart) - x < Math.abs(x - slides[prev].xStart)
        ? index
        : prev,
    0,
  );

export let passiveFalseIfSupported: boolean | AddEventListenerOptions = false;

try {
  const noop = () => {
    // noop
  };
  window.addEventListener(
    'test' as unknown as any,
    noop,
    Object.defineProperty({}, 'passive', {
      get: function () {
        passiveFalseIfSupported = { passive: false };
      },
    }),
  );
  window.removeEventListener('test' as unknown as any, noop, false);
} catch (err) {
  // noop
}
