import { useCallback, useEffect } from 'react';

import { useDeviceDetect } from './useDeviceDetect';

export enum AppMessageType {
  UPDATE_USER_FOLLOWING_STATE = 'updateUserFollowingState',
  UPDATE_BRAND_FOLLOWING_STATE = 'updateBrandFollowingState',
  UPDATE_HASHTAG_FOLLOWING_STATE = 'updateHashtagFollowingState',
  UPDATE_GOODS_LIKE_STATE = 'updateGoodsLikeState',
  UPDATE_ARTICLE_LIKE = 'updateArticleLike',
  UPDATE_ARTICLE_COMMENT_PREVIEW = 'updateArticleCommentPreview',
  COLLECT_STYLE = 'collectStyle',
}

export type AppMessageFunction<T, R = unknown> = (
  payload: T,
) => void | Promise<R> | R;

interface AppBridgeMessageEvent<T> extends MessageEvent {
  readonly data: {
    event: string;
    payload?: T;
  };
}

/**
 * https://www.notion.so/styleshare/02c6d2e8d3de4b8a9712bac4e7e3637f
 * 앱 → 웹간 postMessage를 구독합니다.
 *
 * @param eventType 이벤트 타입
 * @param handleAppMessage 이벤트 받았을 때 callback 함수. payload 값을 받습니다.
 */
export function useInAppMessage<T, R = unknown>(
  eventType: AppMessageType | string,
  handleAppMessage: AppMessageFunction<T, R>,
) {
  const { native } = useDeviceDetect();

  const handleEventMessage = useCallback(
    (event: AppBridgeMessageEvent<T>) => {
      const { origin, data } = event;

      if (origin !== window.location.origin) {
        return;
      }
      if (data?.event !== eventType) {
        return;
      }
      if (!data?.payload) {
        return;
      }

      handleAppMessage(data.payload);
    },
    [handleAppMessage, eventType],
  );

  useEffect(() => {
    if (!native) {
      return;
    }
    window.addEventListener('message', handleEventMessage);
    return () => {
      window.removeEventListener('message', handleEventMessage);
    };
  }, [native, handleEventMessage]);
}
