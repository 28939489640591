import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowDown } from '@styleshare/react-icons/line';

import { AdaptiveLink } from '../AdaptiveLink';

const siteLinks = [
  {
    appHref: `stsh://web/${Buffer.from(
      'https://www.styleshare.kr/privacy',
    ).toString('base64')}`,
    emphasis: true,
    href: 'https://www.styleshare.kr/privacy',
    label: '개인정보 처리방침',
  },
  {
    appHref: `stsh://web/${Buffer.from(
      'https://www.styleshare.kr/terms-of-use',
    ).toString('base64')}`,
    emphasis: true,
    href: 'https://www.styleshare.kr/terms-of-use',
    label: '서비스 이용약관',
  },
];
interface SimpleFooterProps {
  className?: string;
}
export const SimpleFooter = ({ className }: SimpleFooterProps) => {
  return (
    <StyledFooter className={className}>
      <SectionA />
      <SectionB />
    </StyledFooter>
  );
};
const StyledFooter = styled.footer`
  /* empty */
`;

const SectionA = () => (
  <StyledSectionA>
    <p className="content-tel">
      <b>고객센터</b>
    </p>
    <p className="open-time">help@styleshare.kr</p>
    <hr />
    <ul className="site-links">
      {siteLinks.map(({ label, href, appHref, emphasis }, key) => {
        return (
          <li key={key}>
            <p>
              <AdaptiveLink
                appHref={appHref}
                className={emphasis ? 'emphasis' : ''}
                href={href}
                rel="noopener noreferrer"
                target="_blank"
              >
                {label}
              </AdaptiveLink>
            </p>
          </li>
        );
      })}
    </ul>
    {/* <ul className="sns-links">
      {snsLinks.map(({ label, href, Icon }, key) => (
        <li key={key}>
          <a href={href} rel="noopener noreferrer" target="_blank">
            <Icon aria-label={label} height="20" width="20" />
          </a>
        </li>
      ))}
    </ul> */}
  </StyledSectionA>
);
const SectionB = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) {
      // 최하단에서 푸터가 열릴때, 스크롤이 따라 내려가지 않는 현상을 방지
      setTimeout(() => {
        window.scrollTo({ top: 999999 });
      }, 300);
    }
  }, [visible]);
  return (
    <StyledSectionB>
      <Arcodien>
        <button className="button" onClick={() => setVisible(true)}>
          (주)무신사 사업자정보
          {!visible && <ArrowDown fontSize={16} />}
        </button>
        {visible && (
          <div className="content">
            <div>
              <address>
                <p>대표자 : 한문일</p>
                <p>
                  사업자등록번호 :
                  <AdaptiveLink
                    appHref={`stsh://web/${Buffer.from(
                      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2118879575&apv_perm_no=',
                    ).toString('base64')}`}
                    className="link"
                    href={
                      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2118879575'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    211-88-79575
                  </AdaptiveLink>
                </p>
                <p>호스팅 사업자 : (주)무신사</p>
                <p>통신판매업 : 2012-서울강남-01897</p>
                <p>개인정보보호책임자: 이인섭</p>
                <p>
                  주소 : 서울특별시 성동구 아차산로 13길 11, 1층 (성수동2가,
                  무신사캠퍼스 N1)
                </p>
              </address>
            </div>
          </div>
        )}
      </Arcodien>
    </StyledSectionB>
  );
};

const StyledSectionA = styled.div(
  ({ theme: { color, fontSize } }) => css`
    padding: 20px 16px;
    background-color: rgb(243, 243, 243);
    p {
      color: ${color.gray60};
      font-size: ${fontSize.s13};
      line-height: 17px;
      b {
        color: ${color.gray70};
        font-size: ${fontSize.s14};
        line-height: 16px;
      }
    }
    a {
      &.emphasis {
        font-weight: bold;
      }
    }
    ul {
      margin-bottom: 20px;
      :last-child {
        margin-bottom: 0;
      }
    }
    .content-tel {
      margin-bottom: 4px;
    }
    .open-time {
      margin-bottom: 20px;
    }
    hr {
      width: 40px;
      margin: 20px 0;
      border-color: ${color.gray40};
    }
    .site-links {
      display: grid;
      grid-row-gap: 16px;
      grid-column: refeat(2, 1fr);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sns-links {
      display: flex;
      gap: 16px;
      margin-left: -4px;
      a {
        padding: 4px;
      }
      svg {
        color: ${color.gray50};
      }
    }
  `,
);

const StyledSectionB = styled.div`
  color: ${({ theme }) => theme.color.gray60};
  font-size: ${({ theme }) => theme.fontSize.s13};
  background-color: #ebebeb;

  address {
    margin-bottom: 12px;
    font-style: normal;
    line-height: 20px;

    a {
      text-decoration: underline;
    }
  }
`;

const Arcodien = styled.div`
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding: 0 16px;
    color: ${({ theme }) => theme.color.gray60};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.s14};
    &[aria-expanded='true'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .content {
    padding: 12px 16px;
  }
`;
