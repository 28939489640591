import React from 'react';
import styled from 'styled-components';

export interface AspectRatioProps {
  className?: string;
  children?: React.ReactNode;
  heightRatio: number;
  widthRatio: number;
}

/**
 * <AspectRatio />는 child 엘리먼트가 aspect ratio을 유지할 수 있도록 합니다.
 */
export const AspectRatio = ({
  children,
  className,
  heightRatio,
  widthRatio,
}: AspectRatioProps) => {
  return (
    <Wrapper className={className}>
      <Sizer
        style={{ paddingBottom: `calc(${heightRatio} / ${widthRatio} * 100%)` }}
      />
      {children}
    </Wrapper>
  );
};

const Sizer = styled.div`
  /* no style */
`;

const Wrapper = styled.div`
  position: relative;

  & > *:not(${Sizer}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
