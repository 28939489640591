import { useEffect } from 'react';
import { amplitudeScripts } from '../utils/amplitude.util';

export interface AmplitudeParams {
  amplitudeId: string;
  enabled?: boolean;
}

export const useAmplitude = (params: AmplitudeParams) => {
  const { amplitudeId, enabled = true } = params ?? { amplitudeId: '' };

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!amplitudeId) {
      return;
    }

    const { insertScript } = amplitudeScripts(amplitudeId);
    insertScript();
  }, [enabled, amplitudeId]);
};
