import React from 'react';
import styled from 'styled-components';

import { IMAGES_WIDTHS } from '../../constants';
import { FixedImage, FixedImageProps } from '../Image';

interface Props extends Omit<FixedImageProps, 'imageId' | 'width' | 'height'> {
  imageId?: number | string | null;
  size: typeof IMAGES_WIDTHS[number];
  className?: string;
  noImageIconAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

export const Thumbnail = ({
  imageId,
  size,
  alt,
  className,
  lazy,
  noImageIconAs,
}: Props) => {
  if (!imageId || imageId === '0') {
    return (
      <StyledDiv $size={size} className={className}>
        <StyledNoImageIcon as={noImageIconAs} />
      </StyledDiv>
    );
  }

  return (
    <StyledFixedImage
      $size={size}
      alt={alt}
      className={className}
      height={size}
      imageId={imageId}
      lazy={lazy}
      width={size}
    />
  );
};

const StyledDiv = styled.div<{ $size: number }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray10};
  border-radius: ${({ theme }) => theme.radius.pill};
`;

const StyledFixedImage = styled(FixedImage)<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ theme }) => theme.radius.pill};

  &::after {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 9999px;
    content: '';
  }
`;

const StyledNoImageIcon = styled.svg`
  display: block;
`;
