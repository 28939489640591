import { findRawSlide, findSlideIndex, Slide } from './swiperUtil';
import mod from '../../utils/math/mod';

type SwiperStatus = 'idle' | 'swiping' | 'transitioning';

interface SwiperState {
  status: SwiperStatus;
  index: number;
  x: number;
  rawIndex: number;
  rawX: number;
}

interface SwipeMovePayload {
  slides: Slide[];
  dx: number;
  gap: number;
  lastSnapPoint: number;
  loop: boolean;
}

interface SwipeEndPayload {
  slides: Slide[];
  vx: number;
  gap: number;
  lastSnapPoint: number;
  loop: boolean;
}

interface TransitionStartPayload {
  slides: Slide[];
  rawIndex: number;
  gap: number;
  lastSnapPoint: number;
  loop: boolean;
}

interface TransitionEndPayload {
  slides: Slide[];
  lastSnapPoint: number;
  loop: boolean;
}

interface ResizePayload {
  slides: Slide[];
  lastSnapPoint: number;
  loop: boolean;
}

type SwiperAction =
  | { type: 'swipeStart' }
  | { type: 'swipeMove'; payload: SwipeMovePayload }
  | { type: 'swipeEnd'; payload: SwipeEndPayload }
  | { type: 'transitionStart'; payload: TransitionStartPayload }
  | { type: 'transitionEnd'; payload: TransitionEndPayload }
  | { type: 'resize'; payload: ResizePayload };

export const initialSwiperState: SwiperState = {
  index: 0,
  rawIndex: 0,
  rawX: 0,
  status: 'idle',
  x: 0,
};

const SWIPE_ELASTIC = 0.5;
const SWIPE_VELOCITY_THRESHOLD = 0.25;

export const swiperReducer = (
  state: SwiperState,
  action: SwiperAction,
): SwiperState => {
  switch (action.type) {
    case 'swipeStart': {
      return {
        ...state,
        status: 'swiping',
      };
    }
    case 'swipeMove': {
      let dx = action.payload.dx;

      if (
        !action.payload.loop &&
        (state.rawX < 0 || state.rawX > action.payload.lastSnapPoint)
      ) {
        dx = dx * SWIPE_ELASTIC;
      }

      const nextRawX = state.rawX - dx;

      const beforeRawIndex = state.rawIndex - 1;
      const afterRawIndex = state.rawIndex + 1;

      const beforeRawSlide = findRawSlide(
        action.payload.slides,
        action.payload.gap,
        beforeRawIndex,
      );
      const currentRawSlide = findRawSlide(
        action.payload.slides,
        action.payload.gap,
        state.rawIndex,
      );
      const afterRawSlide = findRawSlide(
        action.payload.slides,
        action.payload.gap,
        afterRawIndex,
      );

      const rawIndexOffset =
        findSlideIndex(
          [beforeRawSlide, currentRawSlide, afterRawSlide],
          nextRawX,
        ) - 1;

      const nextRawIndex = state.rawIndex + rawIndexOffset;

      return {
        ...state,
        rawIndex: nextRawIndex,
        rawX: nextRawX,
        status: 'swiping',
      };
    }
    case 'swipeEnd': {
      let nextRawIndex = state.rawIndex;

      // 스와이프를 크게 하지 않아도 가속도가 충분히 빠르면 슬라이드를 넘깁니다.
      if (
        state.index === nextRawIndex &&
        Math.abs(action.payload.vx) >= SWIPE_VELOCITY_THRESHOLD
      ) {
        nextRawIndex -= Math.sign(action.payload.vx);
      }

      // loop 모드가 아니라면 nextRawIndex가 슬라이드 개수 범위를 벗어나지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextRawIndex < 0) {
          nextRawIndex = 0;
        } else if (nextRawIndex > action.payload.slides.length - 1) {
          nextRawIndex = action.payload.slides.length - 1;
        }
      }

      const nextRawSlide = findRawSlide(
        action.payload.slides,
        action.payload.gap,
        nextRawIndex,
      );

      let nextRawX = nextRawSlide.xStart;

      const nextIndex = mod(nextRawIndex, action.payload.slides.length);

      // loop 모드가 아니라면 lastSnapPoint를 초과하지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextRawX > action.payload.lastSnapPoint) {
          nextRawX = action.payload.lastSnapPoint;
        }
      }

      return {
        ...state,
        index: nextIndex,
        rawIndex: nextRawIndex,
        status: 'transitioning',
        x: nextRawX,
      };
    }
    case 'transitionStart': {
      let nextRawIndex = action.payload.rawIndex;

      // loop 모드가 아니라면 nextRawIndex가 슬라이드 개수 범위를 벗어나지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextRawIndex < 0) {
          nextRawIndex = 0;
        } else if (nextRawIndex > action.payload.slides.length - 1) {
          nextRawIndex = action.payload.slides.length - 1;
        }
      }

      const nextRawSlide = findRawSlide(
        action.payload.slides,
        action.payload.gap,
        nextRawIndex,
      );

      let nextRawX = nextRawSlide.xStart;

      const nextIndex = mod(nextRawIndex, action.payload.slides.length);

      // loop 모드가 아니라면 lastSnapPoint를 초과하지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextRawX > action.payload.lastSnapPoint) {
          nextRawX = action.payload.lastSnapPoint;
        }
      }

      return {
        ...state,
        index: nextIndex,
        rawIndex: nextRawIndex,
        status: 'transitioning',
        x: nextRawX,
      };
    }
    case 'transitionEnd': {
      let nextX = action.payload.slides[state.index].xStart;

      // loop 모드가 아니라면 lastSnapPoint를 초과하지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextX > action.payload.lastSnapPoint) {
          nextX = action.payload.lastSnapPoint;
        }
      }

      return {
        ...state,
        rawIndex: state.index,
        rawX: nextX,
        status: 'idle',
        x: nextX,
      };
    }
    case 'resize': {
      let nextX = action.payload.slides[state.index].xStart;

      // loop 모드가 아니라면 lastSnapPoint를 초과하지 않도록 합니다.
      if (!action.payload.loop) {
        if (nextX > action.payload.lastSnapPoint) {
          nextX = action.payload.lastSnapPoint;
        }
      }

      return {
        ...state,
        rawX: nextX,
        x: nextX,
      };
    }
    default: {
      return state;
    }
  }
};
