import {
  useQueryClient,
  MutationFunction,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { ErrorModel } from '@styleshare/models';

/**
 * 쿼리를 수동으로 호출한다.
 * ex. withInteraction HOC와 사용. onVisible 이벤트.
 *
 * # 사용 예시
 *
 * ## SERVICE
 * const [loadBrandMarkets, { data, isLoading }] = useLazyQuery(
 *   CREATOR_MARKET_LIST_CACHE_GROUP,
 *   getRisingBrandList,
 * );
 * ....
 * return {
 *   ...,
 *   loadBrandMarkets
 * }
 *
 * ## CONTAINER
 * const { ..., loadBrandMarkets } = useBrandMarketList();
 *
 * return (
 *   <WithInteractionWrappingComponent (ex. FeedHomeSection)
 *     onVisible={loadBrandMarkets}
 **/
export function useLazyQuery<TResult, TError = ErrorModel, TVariables = void>(
  queryKey: string,
  mutationFn: MutationFunction<TResult, TVariables>,
): UseMutationResult<TResult, TError, TVariables, unknown> {
  const queryClient = useQueryClient();

  return useMutation<TResult, TError, TVariables, unknown>(mutationFn, {
    onSuccess: (response: TResult, params: TVariables) => {
      queryClient.setQueryData([queryKey, params], response);
    },
  });
}
