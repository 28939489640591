import React from 'react';
import { Menu, MenuProps } from 'reakit/Menu';
import styled from 'styled-components';

export const DropdownMenuList = (props: MenuProps) => {
  return <StyledMenu {...props} />;
};

const StyledMenu = styled(Menu)`
  padding: 10px 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  box-shadow: 0 5px 12px ${({ theme }) => theme.color.black_12};
`;
