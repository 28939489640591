import { createAPIClient } from '../createAPIClient';
import { env } from '../env';

export const neoSearchAPI = createAPIClient(
  env.endPoint.neoSearchApiBaseUrl,
  {
    Accept: 'application/json',
  },
  true,
  { maxRedirects: 0 },
);
