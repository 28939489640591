import { useEffect, useMemo } from 'react';

import { ActionType, dispatch, useStore } from './toastStore';
import { removeToast } from './toastUtil';

export const useToast = () => {
  const { toasts, pausedAt } = useStore();

  useEffect(() => {
    if (pausedAt) {
      return;
    }

    const now = Date.now();
    const timeouts = toasts.map((toast) => {
      if (!toast.autoDismiss) {
        return;
      }

      const durationLeft =
        toast.autoDismiss + toast.pauseDuration - (now - toast.createdAt);

      if (durationLeft < 0) {
        if (toast.status === 'visible') {
          removeToast(toast.id);
        }
        return;
      }

      return setTimeout(() => removeToast(toast.id), durationLeft);
    });

    return () => {
      timeouts.forEach((timeout) => timeout && clearTimeout(timeout));
    };
  }, [toasts, pausedAt]);

  const handlers = useMemo(
    () => ({
      endPause: () => {
        if (pausedAt) {
          dispatch({ time: Date.now(), type: ActionType.END_PAUSE });
        }
      },
      startPause: () => {
        dispatch({
          time: Date.now(),
          type: ActionType.START_PAUSE,
        });
      },
    }),
    [pausedAt],
  );

  return {
    handlers,
    toasts,
  };
};
