import { useEffect, useMemo, useState } from 'react';

interface Result {
  hours: number;
  minutes: number;
  seconds: number;
  days: number;
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

/**
 * Date 객체로 받은 두 개의 값을 비교하여 timer를 작동시키는 훅
 */
export const useTimer = (start: Date, end: Date) => {
  const [diff, setDiff] = useState(end.getTime() - start.getTime());

  const result = useMemo<Result>(() => {
    const dayCrumb = diff % DAY;
    const hourCrumb = dayCrumb % HOUR;
    const minuteCrumb = hourCrumb % MINUTE;

    return {
      days: Math.floor(diff / DAY),
      hours: Math.floor(dayCrumb / HOUR),
      minutes: Math.floor(hourCrumb / MINUTE),
      seconds: Math.floor(minuteCrumb / SECOND),
    };
  }, [diff]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDiff((remains) => {
        const next = remains - SECOND;
        if (next < 0) {
          clearTimeout(timer);
          return remains;
        }
        return next;
      });
    }, SECOND);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return result;
};
