import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const SwiperTrack = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <StyledDiv ref={ref} {...props}>
        {children}
      </StyledDiv>
    );
  },
);

SwiperTrack.displayName = 'SwiperTrack';

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  touch-action: pan-x pan-y;
`;
