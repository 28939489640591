import { useRef, useEffect } from 'react';
import { settingUserProfileDataLayer } from '../google-tag-manager/utils';
import { ProfileSchema } from '../types/data';

interface UseUpdateUserProfileProps {
  profile?: ProfileSchema | null;
}
export const useUpdateUserProfile = ({
  profile,
}: UseUpdateUserProfileProps) => {
  const isSet = useRef(false);

  useEffect(() => {
    if (typeof window === 'undefined' || isSet.current) return;

    if (profile) {
      settingUserProfileDataLayer(profile);
      isSet.current = true;
    }
  }, [profile]);
};
