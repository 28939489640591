import React from 'react';
import styled from 'styled-components';

import { profileImageUrl } from '~modules/@shared/utils';

import { Image } from '../Image';

export const ThumbnailFallbackUser = () => {
  return (
    <StyledImage
      alt=""
      height={1}
      src={profileImageUrl(undefined, 80)}
      width={1}
    />
  );
};

const StyledImage = styled(Image)`
  flex: 1;
`;
