/* eslint-disable no-console */
import { useCallback } from 'react';

import { env } from '~core';

import { useDeviceDetect } from './useDeviceDetect';
import {
  AnalyticsEventPayload,
  sendMessageToAmplitude,
  sendMessageToNativeApp,
} from '../utils/analytics';

export interface UseAnalyticsEventParams extends AnalyticsEventPayload {
  options?: UseAnalyticsEventOptions;
}

export interface UseAnalyticsEventOptions {
  debug?: boolean;
  callback?: (payload: AnalyticsEventPayload) => void;
}

export function useAnalyticsEvent({
  event,
  parameters,
  options,
}: UseAnalyticsEventParams) {
  const { native } = useDeviceDetect();

  const track = useCallback(() => {
    if (!event) {
      return;
    }

    if (options?.debug || env.development || env.stage || env.beta) {
      console.log(`[Analytics::${event}] `, parameters);
      return;
    }

    if (native) {
      sendMessageToNativeApp({ event, parameters });
    } else {
      sendMessageToAmplitude({ event, parameters });
    }

    options?.callback?.({ event, parameters });
  }, [event, parameters, options, native]);

  return track;
}
