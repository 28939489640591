const QUANTITY_SYMBOLS: [number, string][] = [
  [Math.pow(10, 0), ''],
  [Math.pow(10, 3), 'K'],
  [Math.pow(10, 6), 'M'],
  [Math.pow(10, 9), 'G'],
  [Math.pow(10, 12), 'T'],
  [Math.pow(10, 15), 'P'],
];
export function numberScale(value: number): string {
  const isNagative = value < 0;
  const v = Math.abs(value);

  let factor = 1;
  let suffix = '';

  for (let index = 0; index < QUANTITY_SYMBOLS.length; index++) {
    const [f, s] = QUANTITY_SYMBOLS[index];

    if (f > v) break;

    factor = f;
    suffix = s;
  }

  let num = v / factor;

  if (num === Math.ceil(num)) {
    num = Math.round(num);
  } else {
    num = parseFloat(num.toFixed(1));
  }

  return `${isNagative ? '-' : ''}${num}${suffix}`;
}
