/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import { baseAPI, env } from '~core';

import UserAgent from './path-scheme/useragent';
import { getNativeAppService } from '../services';

type AnalyticsEventParameters = Record<string, unknown>;

export interface AnalyticsEventPayload {
  event?: string;
  parameters?: AnalyticsEventParameters;
  debug?: boolean;
}

export const sendToAnalyticsEvent = ({
  event,
  parameters = {},
  debug = false,
}: AnalyticsEventPayload) => {
  if (!event) {
    return;
  }

  if (debug || env.development || env.stage) {
    console.log(`[Analytics::${event}] `, parameters);
    return;
  }

  const { isFromApp } = new UserAgent();

  if (!isFromApp) {
    sendMessageToAmplitude({
      event,
      parameters,
    });
    return;
  }

  getNativeAppService().sendAnalytics({
    event,
    parameters,
  });
};

// TODO: 네이티브 이벤트 로깅 구현해야 함
export const sendMessageToNativeApp = ({
  event,
  parameters = {},
}: AnalyticsEventPayload) => {
  if (!event) {
    return;
  }

  getNativeAppService().sendAnalytics({ event, parameters });
};

export const sendMessageToAmplitude = ({
  event,
  parameters = {},
}: AnalyticsEventPayload) => {
  if (!event) {
    return;
  }
  try {
    window?.amplitude?.getInstance?.().logEvent?.(event, parameters);
  } catch (error) {
    console.log({
      error,
      event,
      instance: window?.amplitude?.getInstance?.(),
      parameters,
    });
    try {
      // @ts-ignore
      window.amplitude_sub = window.amplitude;
      window?.amplitude?.getInstance?.()?.logEvent?.(event, parameters);
    } catch (error) {
      console.log('retry error amplitude');
      console.log({ error });
    }
  }
};

export const sendAdClick = (parameters: AnalyticsEventParameters) => {
  if (env.development) {
    console.log('[AD::Click]', parameters);
    return;
  }

  baseAPI.get('/_tracking/advertisement/click', parameters);
};

export const sendAdImpressions = (parameters: AnalyticsEventParameters) => {
  if (env.development) {
    console.log('[AD::Impressions]', parameters);
    return;
  }

  baseAPI.post('/_tracking/ad/impressions', parameters);
};
