import React from 'react';
import styled from 'styled-components';

import { AdaptiveLink } from '../AdaptiveLink';
import { FixedImage } from '../Image';

interface Props {
  brandName: string;
  className?: string;
  discountRate?: number;
  goodsName: string;
  goodsId: string;
  imageId: string;
  linkParams?: Record<string, string>;
  linkTarget?: string;
  price: number;
}

const IMAGE_SIZE = 64;
const WRAPPER_SIZE = 56;

/**
 * GoodsTag single
 * @see {@link https://zpl.io/angKpOA}
 *
 * GoodsTag multiple
 * @see {@link https://zpl.io/blQgxdG}
 */
export const GoodsTag = React.forwardRef<HTMLElement, Props>(
  (
    {
      brandName,
      className,
      discountRate,
      goodsName,
      goodsId,
      imageId,
      linkParams = {},
      linkTarget = '_blank',
      price,
    },
    ref,
  ) => {
    const queryString = React.useMemo(
      () => new URLSearchParams(linkParams).toString(),
      [linkParams],
    );

    return (
      <StyledGoodsTagWrap ref={ref} className={className}>
        <StyledAdaptiveLink
          appHref={`stsh://goods/${goodsId}?${queryString}`}
          rel="noopener noreferrer"
          target={linkTarget}
          to={`/goods/${goodsId}?${queryString}`}
        >
          <StyledFixedImage
            alt={goodsName}
            height={IMAGE_SIZE}
            imageId={imageId}
            width={IMAGE_SIZE}
            lazy
          />
          <StyledBodyWrap>
            <StyledNameWrap>
              <span className="brand">{brandName}</span>
              <span>{goodsName}</span>
            </StyledNameWrap>
            <StyledPriceWrap>
              {typeof discountRate === 'number' && discountRate > 0 && (
                <span className="discount">{discountRate}%</span>
              )}
              {/* // TODO: i18n */}
              <span>{price.toLocaleString()}원</span>
            </StyledPriceWrap>
          </StyledBodyWrap>
        </StyledAdaptiveLink>
      </StyledGoodsTagWrap>
    );
  },
);
GoodsTag.displayName = 'GoodsTag';

const StyledGoodsTagWrap = styled.article`
  height: 56px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray10};
  border-radius: 4px;
`;
const StyledAdaptiveLink = styled(AdaptiveLink)`
  display: flex;
  flex: 1 1 auto;
`;
const StyledFixedImage = styled(FixedImage)`
  position: relative;
  display: inline-block;
  flex: 0 0 ${WRAPPER_SIZE}px;
  width: ${WRAPPER_SIZE}px;
  height: ${WRAPPER_SIZE}px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${({ theme }) => theme.color.black_03};
    content: '';
  }
`;
const StyledBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 56px);
  padding: 10px;
`;
const StyledNameWrap = styled.h6`
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray100};
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.s12};
  text-decoration: none;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  & > .brand {
    margin-right: 3px;
    font-weight: bold;
    white-space: nowrap;
  }
`;
const StyledPriceWrap = styled.p`
  margin-top: 3px;
  color: ${({ theme }) => theme.color.gray100};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.s12};

  & > .discount {
    margin-right: 3px;
    color: ${({ theme }) => theme.color.system_red};
  }
`;
