import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  className?: string;
  gap?: number;
  wrapGap?: number;
}

export const Carousel = ({
  children,
  className,
  gap = 0,
  wrapGap = 12,
}: Props) => {
  return (
    <Row className={className}>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? (
          <Column $gap={gap} $wrapGap={wrapGap}>
            {child}
          </Column>
        ) : null,
      )}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: none;
  -webkit-overflow-scrolling: touch;
`;

const Column = styled.div<{ $gap: number; $wrapGap: number }>`
  scroll-snap-align: start;
  flex: none;
  padding: 0 ${({ $gap }) => $gap / 2}px;

  &:first-of-type {
    padding-left: ${({ $wrapGap }) => $wrapGap}px;
  }

  &:last-of-type {
    padding-right: ${({ $wrapGap }) => $wrapGap}px;
  }
`;
