import { stripIndents } from 'common-tags';
import { DataLayerParams, GoogleTagManagerParams } from '../models';
import { initSshareLayer } from './initSshareLayer';

declare const window: any;

const initDataLayers = (
  dataLayerName: string,
  data: Pick<DataLayerParams, 'dataLayer'>['dataLayer'],
) => {
  window[dataLayerName] = window[dataLayerName] || [];

  if (data) {
    window[dataLayerName].push(data);
  }
};

export const sendDataLayer = ({
  dataLayer: data,
  dataLayerName = 'dataLayer',
}: DataLayerParams) => {
  window[dataLayerName].push(data);
};

export const gtmScripts = ({
  gtmId,
  dataLayer: data,
  dataLayerName = 'dataLayer',
}: GoogleTagManagerParams) => {
  if (!gtmId) {
    throw new Error('GTM ID가 필요합니다');
  }

  const makeNoScript = (scriptId: string) => {
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.width = '0';
    iframe.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = document.createElement('noscript');
    noscript.id = scriptId;
    noscript.appendChild(iframe);

    return noscript;
  };

  const makeScript = (scriptId: string) => {
    const script = document.createElement('script');
    script.id = scriptId;
    script.innerHTML = stripIndents`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${gtmId}')
    `;

    return script;
  };

  const insertNoScript = () => {
    const sshareLayerKey = `${gtmId}_${dataLayerName}_noscript`;

    if (window.sshareLayer[sshareLayerKey]) {
      return;
    }

    const noscript = makeNoScript(sshareLayerKey);
    document.body.insertBefore(noscript, document.body.childNodes[0]);
    window.sshareLayer[sshareLayerKey] = noscript;

    return noscript;
  };

  const insertScript = () => {
    const sshareLayerKey = `${gtmId}_${dataLayerName}`;

    if (window.sshareLayer[sshareLayerKey]) {
      return;
    }

    const gtmScript = makeScript(sshareLayerKey);

    document.head.insertBefore(gtmScript, document.head.childNodes[0]);
    window.sshareLayer[sshareLayerKey] = gtmScript;

    initDataLayers(dataLayerName, data);

    return gtmScript;
  };

  initSshareLayer();

  return {
    insertNoScript,
    insertScript,
  };
};
