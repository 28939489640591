import { createAPIClient } from '../createAPIClient';
import { env } from '../env';

export const shopGatewayAPI = createAPIClient(
  env.endPoint.shopGatewayApiBaseUrl,
  {
    Accept: 'application/json',
  },
  true,
  { maxRedirects: 0 },
);

export const shopGatewayFormDataAPI = createAPIClient(
  env.endPoint.shopGatewayApiBaseUrl,
  {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  },
);
