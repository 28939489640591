import React from 'react';
import styled from 'styled-components';

import { DirectionForward } from '@styleshare/react-icons/line';

import { AdaptiveLink } from '../AdaptiveLink';

interface Props {
  className?: string;
  href?: string;
  appHref?: string;
  to?: string;
  onClick?: () => void;
}

export const CarouselMoreButton = ({
  className,
  href,
  appHref,
  to,
  onClick,
}: Props) => {
  return (
    <Wrapper className={className}>
      <MoreButton appHref={appHref} href={href} to={to} onClick={onClick}>
        <MoreIcon />
      </MoreButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MoreIcon = styled(DirectionForward)`
  width: 30px;
  height: 30px;
  fill: ${({ theme }) => theme.color.gray60};
`;

const MoreButton = styled(AdaptiveLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 100%;
`;
