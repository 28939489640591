import { createGlobalStyle } from 'styled-components';

export const WebviewGlobalStyle = createGlobalStyle`
  /* 웹뷰에서 모든 스크롤바를 숨김 */

  *::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    appearance: none;
  }

  body {
    /* 웹뷰에서 다 선택이 불가능하게 함 */
    user-select: none;

    /* 롱프레스 시 나오는 팝업을 제어 */
    -webkit-touch-callout: none;

    /* 링크 터치 시 기본 영역 색상을 제어 */
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }
`;
