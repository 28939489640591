import { createAPIClient } from '../createAPIClient';
import { env } from '../env';

export const baseAPI = createAPIClient(
  env.endPoint.apiBaseUrl,
  {
    Accept: 'application/json',
  },
  true,
  { maxRedirects: 0 },
);
