import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  slidesPerView?: number;
  gap?: number;
  peek?: number;
  label?: string;
}

export const SwiperSlide = ({
  children,
  style,
  slidesPerView,
  gap = 0,
  peek = 0,
  label,
}: Props) => {
  return (
    <StyledDiv
      $gap={gap}
      $peek={peek}
      $slidesPerView={slidesPerView}
      aria-label={label}
      style={style}
    >
      {children}
    </StyledDiv>
  );
};

export const StyledDiv = styled.div<{
  $gap: number;
  $peek: number;
  $slidesPerView?: number;
}>`
  position: relative;
  display: block;
  flex-shrink: 0;
  width: ${({ $gap, $peek, $slidesPerView }) =>
    $slidesPerView
      ? `calc((100% - ${$peek}px) / ${$slidesPerView} - ${$gap / 2}px) `
      : 'auto'};
  margin-right: ${({ $gap }) => $gap / 2}px;
  margin-left: ${({ $gap }) => $gap / 2}px;
  white-space: normal;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
