import React from 'react';
import { MenuItem, MenuItemProps } from 'reakit/Menu';
import type { As, PropsWithAs } from 'reakit-utils/types';
import styled from 'styled-components';

type Props = MenuItemProps & PropsWithAs<unknown, As>;

export const DropdownMenuItem = ({ as, ...props }: Props) => {
  const handleClick: React.MouseEventHandler = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }

    // 클릭하면 닫습니다.
    if (props.hide) {
      props.hide();
    }
  };
  return <StyledMenuItem {...props} forwardedAs={as} onClick={handleClick} />;
};

const StyledMenuItem = styled(MenuItem)`
  display: block;
  width: 100%;
  padding: 10px 20px;
  color: ${({ theme }) => theme.color.gray100};
  font-size: ${({ theme }) => theme.fontSize.s13};
  text-align: left;
  user-select: none;

  &:active,
  &:hover {
    background-color: ${(props) => props.theme.color.gray10};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray30};
  }
`;
