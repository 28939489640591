import { BaseNativeAppService } from './BaseNativeAppService';
import {
  AnalyticsEventParams,
  ChangeSearchResultTabQuery,
  CollectStyleParams,
  HandlerResultType,
  NativeAppService,
  SelectServiceHomeAsDefaultTabParams,
  SetShareInformationParams,
  UpdateArticleLikeParams,
} from '../../models';

enum AndroidInterfaceNameType {
  ProgressBarInterface = 'ProgressBarInterface',
  SearchResultInterface = 'SearchResultInterface',
  OrderHistoryInterface = 'OrderHistoryInterface',
  StyleCollectInterface = 'StyleCollectInterface',
  CartInterface = 'CartInterface',
  EventTrackerInterface = 'EventTrackerInterface',
  ServiceHomeInterface = 'ServiceHomeInterface',
  ArticleStateInterface = 'ArticleStateInterface',
  ShareInterface = 'ShareInterface',
}

interface AndroidMessagehandlers {
  [name: string]: (payload?: string | number) => void;
}

interface AndroidWebkitWindow {
  [name: string]: AndroidMessagehandlers;
}

const MESSAGE_MAP: Record<string, AndroidInterfaceNameType> = {
  analytics: AndroidInterfaceNameType.EventTrackerInterface,
  changeSearchResultTab: AndroidInterfaceNameType.SearchResultInterface,
  collectStyle: AndroidInterfaceNameType.StyleCollectInterface,
  selectServiceHomeAsDefaultTab: AndroidInterfaceNameType.ServiceHomeInterface,
  setShareInformation: AndroidInterfaceNameType.ShareInterface,
  stopLoading: AndroidInterfaceNameType.ProgressBarInterface,
  updateArticleCommentPreview: AndroidInterfaceNameType.ArticleStateInterface,
  updateArticleLike: AndroidInterfaceNameType.ArticleStateInterface,
};

export class AndroidAppService
  extends BaseNativeAppService
  implements NativeAppService
{
  /**
   * Android 전용 네이티브 앱 기능을 제공하는 서비스.
   */
  constructor() {
    super();
  }
  changeSearchResultTab(params: ChangeSearchResultTabQuery) {
    this.messageHandlers('changeSearchResultTab', params);
  }
  collectStyle(params: CollectStyleParams) {
    this.messageHandlers('collectStyle', params);
  }
  updateArticleCommentPreview() {
    this.messageHandlers('updateArticleCommentPreview');
  }
  updateArticleLike(params: UpdateArticleLikeParams) {
    this.messageHandlers('updateArticleLike', params);
  }
  stopLoading() {
    if (this.messageHandlers('stopLoading') !== HandlerResultType.OK) {
      // setUrlToTemporaryIframe('stsh-web://stop-loading', 'iframe_stopLoading');
    }
  }

  sendAnalytics({ event, parameters }: AnalyticsEventParams) {
    const result = this.messageHandlers('analytics', { event, parameters });

    if (result === HandlerResultType.NOT_FOUND) {
      const qs = new URLSearchParams({ event, ...parameters }).toString();
      window.location.href = 'stsh://analytics?' + qs;
    }
  }
  selectServiceHomeAsDefaultTab(params: SelectServiceHomeAsDefaultTabParams) {
    this.messageHandlers('selectServiceHomeAsDefaultTab', params.userAction);
  }
  setShareInformation(params: SetShareInformationParams) {
    this.messageHandlers('setShareInformation', params);
  }
  destroy() {
    // 서비스가 사라질 때 후속 작업이 필요하다면 채워주세요.
  }
  messageHandlers<T extends Record<string, any> | string | number>(
    name: string,
    payload?: T,
  ) {
    try {
      const win = window as unknown as AndroidWebkitWindow;
      const ns = MESSAGE_MAP[name];

      if (!ns || !win[ns] || !win[ns][name]) {
        return HandlerResultType.NOT_FOUND;
      }

      if (payload) {
        if (typeof payload === 'string' || typeof payload === 'number') {
          win[ns][name](payload);
        } else {
          win[ns][name](JSON.stringify(payload));
        }
      } else {
        win[ns][name]();
      }

      return HandlerResultType.OK;
    } catch (error) {
      const err = error as Error;

      if (err && err.message) {
        // errorReport(err);
      }

      return HandlerResultType.EXCEPTED;
    }
  }
}
