import { env } from '~core';

import { AlertArgs, AppMessageEventArgs } from '../../models';

const WEBVIEW_CLOSE_SCHEME = 'stsh://close';

/**
 * 앱 내에서 새로운 웹뷰를 열어준다.
 *
 * 새창 웹뷰 기능이 가능한 곳
 * - 필터페이지
 * @param path 새웹뷰에서 수행될 URL 경로. 현재 도메인 경로를 기준으로 수행되므로 기 설정된 route path 를 파라미터와 함께 전달한다. 경로나 파라미터에 유니코드(ex: 한글)가 섞여 있다면 반드시 encode 하여 주어야 한다.
 * @param title 웹뷰에 적용 될 제목
 * @see https://www.notion.so/styleshare/19-10-1-7c7fafaf9d5c4cff86a917ff9cc56b0d#266409fb9838497b9e00450b469c936e
 */
export function openNewWebviewForSearchFilter(
  path: string,
  title?: string,
): void {
  if (!path) {
    return;
  }
  try {
    const sTail = title ? `?title=${encodeURIComponent(title)}` : '';

    window.location.href = `stsh-web://open-product-filter/${btoa(
      `${window.location.origin}${path}`,
    )}${sTail}`;
  } catch (error) {
    //
  }
}

/**
 * 앱 내에서 Alert 창을 띄운다
 *
 * @param data AlertArgs
 */
export function showAlertAndRedirect({
  message,
  title = 'StyleShare',
  redirect,
}: AlertArgs): void {
  try {
    if (redirect?.includes(WEBVIEW_CLOSE_SCHEME)) {
      alert(message);
      window.location.href = WEBVIEW_CLOSE_SCHEME;
      return;
    }

    const textComponent = encodeURIComponent(message);
    const titleComponent = encodeURIComponent(title);
    const redirectComponent = redirect ? encodeURIComponent(redirect) : '';

    window.location.href =
      `stsh://alert?message=${textComponent}&title=${titleComponent}` +
      (redirect ? `&redirect=${redirectComponent}` : '');
  } catch (error) {
    //
  }
}

/**
 * 네이티브앱의 메시지 이벤트 객체의 유효성을 검증한다.
 * @param eventType 기대하는 이벤트 명칭
 * @param event 검증할 이벤트 객체
 * @returns {boolean} 유효성이 검증되었다면 true, 아니면 false.
 */
export function validateForNativeAppBridgeMessageEvent<T>(
  eventType: string,
  event: AppMessageEventArgs<T>,
) {
  if (env.isServer) {
    return false;
  }
  try {
    if (event.origin !== window.location.origin) {
      return false;
    }
    if (!event.data || !event.data.event || !event.data.payload) {
      return false;
    }
    if (event.data.event !== eventType) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * 네이티브앱에서 전달하는 메시를 검증하고 페이로드 자료를 가져온다.
 * @param type 메시지 이벤트명
 * @param event 검증할 이벤트 객체
 */
export function extractMessagePayload<T extends Record<string, any>>(
  type: string,
  event: AppBridgeMessageEvent<T>,
) {
  if (!validateForNativeAppBridgeMessageEvent(type, event)) {
    return null;
  }
  if (event.data.payload) {
    return event.data.payload;
  }
  return null;
}
