import { grid } from './grid';

/**
 * 네이밍 규칙
 * [<분류>_]<색상명>[_<투명도>]
 */
export const color = {
  black: '#000000',
  black_03: 'rgba(0, 0, 0, 0.03)',
  black_06: 'rgba(0, 0, 0, 0.06)',
  black_12: 'rgba(0, 0, 0, 0.12)',
  black_15: 'rgba(0, 0, 0, 0.15)',
  black_16: 'rgba(0, 0, 0, 0.16)',
  black_80: 'rgba(0, 0, 0, 0.8)',
  darkPoint: '#5860ff',
  gradient_live: 'linear-gradient(to left, #ff21ff, #6f20ff)',
  gray100: '#131518',
  gray100_50: 'rgba(19, 21, 24, 0.5)',
  gray100_compatible: '#131518',
  gray03: '#EEEFF3',
  gray04: '#D5D8DC',
  gray05: '#C2C6CB',
  gray09: '#454E54',
  gray10: '#f0f1f4',
  gray11: '#1D2024',
  gray10_50: 'rgba(240, 241, 244, 0.5)',
  gray10_70: 'rgba(240, 241, 244, 0.5)',
  gray20: '#dadde0',
  gray20_50: '#dadde07f',
  gray30: '#c9cdd2',
  gray30_50: 'rgba(201, 205, 210, 0.5)',
  gray40: '#b2b8be',
  gray40_50: 'rgba(178, 184, 190, 0.5)',
  gray50: '#99a1a8',
  gray5: '#fcfcfd',
  gray60: '#757d86',
  gray70: '#50585f',
  gray80: '#3a3e45',
  gray900: '#1b1d1f',
  gray900_40: 'rgba(27,29,31, 0.4)',
  gray90: '#21242a',
  gray90_97: 'rgba(33, 36, 42, 0.97)',
  lightest_point: '#ede5fd',
  palette_blue: '#0671f4',
  palette_green: '#01b142',
  palette_lightBlue: '#00c6e7',
  palette_lightGreen: '#74da0d',
  palette_mint: '#02cea7',
  palette_orange: '#ee6600',
  palette_pink70: '#d9167d',
  palette_pink: '#ff319e',
  palette_pinkRed: '#ff316b',
  palette_purple: '#a800eb',
  palette_purpleBlue70: '#3c03b1',
  palette_red: '#cf0303',
  palette_yellow: '#ffbb00',
  primary_lightest: '#EBE1FB',
  primary: '#4d00eb',
  point: '#4d00eb',
  point_10: 'rgba(77, 0, 235, 0.1)',
  point_50: 'rgba(77, 0, 235, 0.5)',
  point_70: 'rgba(77, 0, 235, 0.7)',
  point_compatible: '#4d00eb',
  system_blue: '#007aff',
  system_red: '#f81542',
  white: '#ffffff',
  white_05: 'rgba(255, 255, 255, 0.05)',
  white_12: 'rgba(255, 255, 255, 0.12)',
  white_16: 'rgba(255, 255, 255, 0.16)',
  white_32: 'rgba(255, 255, 255, 0.32)',
  white_50: 'rgba(255, 255, 255, 0.5)',
};

export const fontSize = {
  s10: '0.625rem',
  s11: '0.6875rem',
  s12: '0.75rem',
  s13: '0.8125rem',
  s14: '0.875rem',
  s16: '1rem',
  s18: '1.125rem',
  s20: '1.25rem',
  s21: '1.313rem',
  s22: '1.375rem',
  s24: '1.5rem',
  s28: '1.75rem',
};

export const radius = {
  box: '4px',
  chip: '16px',
  input: '18px',
  pill: '9999px',
  text_skeleton: '2px',
};

export const button = {
  g1: `grid-template-columns: repeat(1, minmax(0, 1fr));`,
  default: `
    border-radius: 4px;
    color: ${color.gray100};
    font-weight: bold;
    border: 1px solid ${color.gray20};
    padding: 14px;
  `,
  submit: `
    border-radius: 4px;
    color: white;
    font-weight: bold;
    background-color: ${color.gray100};
    padding: 14px;

    &:disabled {
      background-color: ${color.gray20};
    }
  `,
};

export const breakpoint = [375, 414, 768, 960, 1280];

export const mediaQuery = {
  /* eslint-disable sort-keys-fix/sort-keys-fix */

  /**
   * Super extra small
   * \<= 375px
   * @example 얇은 모바일 기기, 갤럭시 폴드
   *
   */
  xxs: `@media (max-width: 359px)`, // @Todo breakpoint 배열에 추가 후 breakpoint 사용하는 곳 전부 리팩토링 예정
  /**
   * Extra small
   * \>= 375px
   * @example 일반 모바일 기기, iPhone X
   */
  xs: `@media screen and (min-width: ${breakpoint[0]}px)`,
  /**
   * Small
   * \>= 414px
   * @example 큰 모바일 기기, iPhone 8 Plus, iPhone 11 XS Max
   */
  sm: `@media screen and (min-width: ${breakpoint[1]}px)`,
  /**
   * Medium
   * \>= 768px
   * @example 태블릿
   */
  md: `@media screen and (min-width: ${breakpoint[2]}px)`,
  /**
   * Large
   * \>= 960px
   * @example 데스크탑
   */
  lg: `@media screen and (min-width: ${breakpoint[3]}px)`,
  /**
   * Extra large
   * \>= 1280px
   * @example 고해상도 데스크탑
   */
  xl: `@media screen and (min-width: ${breakpoint[4]}px)`,
  /* eslint-enable */
};

export const maxSize = {
  mobileWeb: 550,
};

export const space = {
  h8: '8px',
  h12: '12px',
  h20: '20px',
};

/**
 * @deprecated
 */
export const zIndex = {
  drawer: 140,
  drawerOverlay: 138,
  dropdown: 10,
  gnb: 100,
  gnbOverlay: 98,
  modal: 200,
  modalOverlay: 198,
  daumPostcode: 250,
};

export const theme = {
  breakpoint,
  button,
  color,
  fontSize,
  maxSize,
  mediaQuery,
  radius,
  space,
  zIndex,
  grid,
};

export const darkTheme = {
  ...theme,
  color: {
    ...color,
    black: color.white,
    gray5: color.gray100,
    gray10: color.gray90,
    gray20: color.gray80,
    gray30: color.gray70,
    gray40: color.gray60,
    gray50: color.gray50,
    gray60: color.gray40,
    gray70: color.gray30,
    gray80: color.gray20,
    gray90: color.gray10,
    gray100: color.gray5,
    point: '#5860ff',
    system_blue: '#439af8',
    system_red: '#ee4557',
    white: color.black,
  },
};

export type Theme = typeof theme;

export default theme;
