/**
 * 앱 내 메시지 이벤트 핸들러의 결과 유형에 대한 열거형
 */
export enum HandlerResultType {
  /**
   * Runtime environment 가 서버 환경임
   */
  IS_SERVER,
  /**
   * 요청한 메서드를 앱 내 webview 객체에서 찾을 수 없음
   */
  NOT_FOUND,
  /**
   * 요청 중 예외가 발생되어 catch 되었음
   */
  EXCEPTED,
  /**
   * 정상 수행되었음
   */
  OK,
}

/**
 * 앱 내 Path Interpreter 로 해석된 사용처 정보
 */
export enum PathInterpreterResultType {
  /**
   * 사용처: 검색 필터
   */
  SEARCH_FILTER,
  /**
   * 사용처: 알 수 없음
   */
  UNKNOWN,
}

/**
 * 검색 필터의 하위 필터 페이지에 대한 쿼리 모델.
 */
export interface SearchSubFilterQuery {
  /**
   * 브랜드 ID
   *
   * 여러개일 경우 쉼표(,)로 구분되어 전달된다.
   */
  brandIds?: string;
  /**
   * 카테고리 ID
   *
   * 여러개일 경우 쉼표(,)로 구분되어 전달된다.
   */
  categoryIds?: string;
}

/**
 * 검색 필터의 상위 필터 페이지에 대한 쿼리 모델
 */
export interface SearchFilterQuery extends SearchSubFilterQuery {
  /**
   * 최소 가격
   */
  minPrice?: string;
  /**
   * 최대 가격
   */
  maxPrice?: string;
  /**
   * 무료배송 여부
   */
  freeDelivery?: string | 'true' | '';
}

/**
 * 검색 결과 페이지 이동 쿼리 모델
 */
export interface ChangeSearchResultTabQuery {
  tab: string;
}

/**
 * 스타일카드의 콜렉트를 열기위한 모델
 */
export interface CollectStyleParams {
  styleId: string;
  current_screen: string;
}

export interface AlertArgs {
  message: string;
  title?: string;
  redirect?: string;
}

/**
 * 이벤트 로깅을 위한 모델
 */
export interface AnalyticsEventParams {
  event: string;
  parameters?: Record<string, unknown>;
}

/**
 * 홈 탭을 첫 탭으로 설정하는 모델
 */
export interface SelectServiceHomeAsDefaultTabParams {
  userAction: 'agree' | 'dismiss';
}

/**
 * 앱의 공유하기 버튼에 필요한 데이터를 정의한 모델
 */
export interface SetShareInformationParams {
  webUrl: string; // ex: "https://www.styleshare.kr/goods/{goodsId}",
  mobileShareUrl: string; // ex:  "https://www.styleshare.kr/share/goods/{goodsId}",
  imageUrl: string;
  title: string;
  description?: string;
  itemId: string;
  screenName: string;
  userId?: number;
}

/**
 * 앱에서 웹뷰로 메시지 전달 시 사용되는 자료.
 */
export interface AppMessageEventArgs<T extends Record<string, any>>
  extends MessageEvent {
  readonly data: {
    /**
     * 이벤트 타입.
     */
    event: string;
    /**
     * 전달되는 자료
     */
    payload?: T;
  };
}

/**
 * 아티클 좋아요 클릭 시 이벤트 전달
 */
export interface UpdateArticleLikeParams {
  articleId: string;
  isLiked: boolean;
}

/**
 * 네이티브앱에 요청되는 새창 수행 경로의 내용을 파악하여 어떤 유형의 새창을 열어야 할지 알려준다.
 */
export interface PathInterpreter {
  /**
   * 특정 경로값을 해석하여 실행할 새창 유형을 알려준다.
   * @param path 실행할 경로
   * @returns 알려진 새창 유형.
   */
  interpret(path: string): PathInterpreterResultType;
}

/**
 * 네이티브앱과 통신하거나 제한된 특수 기능을 수행하는 인터페이스를 제공한다.
 *
 * @see https://www.notion.so/styleshare/df838d4fab534e37b78b18ff81ce8e78#8baae6a3f72847ccbb8ddd4dc4f5de20
 * @see https://www.notion.so/styleshare/Native-WebView-1aaf85b4c90149c5b4c3686daccb3407
 *
 * @see https://docs.google.com/spreadsheets/d/1poIJTiOzzvC8QCAf_vUZWs1mE3VA_jbFBpaRr6gw3IA/edit#gid=1403884757
 */
export interface NativeAppService {
  /**
   * 검색 결과 탭 이동
   * @param params 이동할 탭 파라미터
   */
  changeSearchResultTab(params: ChangeSearchResultTabQuery): void;
  /**
   * 스타일카드 콜렉트 모달을 연다
   * @param params 콜렉트열기에 필요한 파라미터
   * @param params.styleId 스타일아이디
   * @param params.current_screen 현재 스크린
   */
  collectStyle(params: CollectStyleParams): void;
  /**
   * 앱내에서 웹뷰 새창을 연다.
   *
   * 현재(2020년) 기준, 필터 페이지 수행만 유효하다.
   * @param path 수행될 경로
   * @param title 새로운 웹뷰의 제목
   */
  openNewWebview(path: string, title?: string): void;
  /**
   * 앱내에서 Alert 창을 띄운다
   *
   * @param params { message: string, title?: string, redirect?: string }
   */
  showAlert(params: AlertArgs): void;
  /**
   * 앱내에서 보여지는 로딩 화면 출력을 중지한다.
   *
   * pull to refresh 기능을 앱에서 사용 할 때 쓰인다.
   *
   * @see https://www.notion.so/styleshare/Native-to-Web-Web-to-Native-Flow-Checklist-07a6581af45d49dbb04bd768375511ad#f72a066d18d549208e8d121f1d57498d
   */
  stopLoading(): void;
  /**
   * 서비스가 파괴되는 선행 작업을 수행 한다.
   *
   * 메모리 누수의 가능성이 있는 기존의 모든 작업들을 중지하거나 취소하고 되돌리는 작업을 한다.
   */
  destroy(): void;
  /**
   * 이벤트 로깅을 위해 호출한다.
   *
   * @params AnalyticsEventParams
   */
  sendAnalytics(params: AnalyticsEventParams): void;
  /**
   * 홈 탭을 첫 탭으로 설정한다.
   */
  selectServiceHomeAsDefaultTab(
    params: SelectServiceHomeAsDefaultTabParams,
  ): void;
  /**
   *
   * 공통웹뷰로 공유하기 관련 정보를 앱으로 전송한다.
   *
   * @param params SetShareInformationParams
   */
  setShareInformation(params: SetShareInformationParams): void;
  /**
   * 아티클 미리 보기 댓글 영역을 업데이트 한다.
   */
  updateArticleCommentPreview(): void;
  /**
   * 아티클 좋아요를 업데이트한다.
   */
  updateArticleLike(params: UpdateArticleLikeParams): void;
}
