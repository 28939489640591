import { AppPathInterpreter } from './AppPathInterpreter';
import {
  openNewWebviewForSearchFilter,
  showAlertAndRedirect,
} from './commonApp.util';
import {
  AlertArgs,
  AnalyticsEventParams,
  PathInterpreterResultType,
  SetShareInformationParams,
} from '../../models';

export abstract class BaseNativeAppService {
  private pathInterpreter = new AppPathInterpreter();

  openNewWebview(path: string, title?: string) {
    const pathType = this.pathInterpreter.interpret(path);

    if (pathType === PathInterpreterResultType.SEARCH_FILTER) {
      openNewWebviewForSearchFilter(path, title);
    }
  }
  showAlert({ message, title, redirect }: AlertArgs) {
    showAlertAndRedirect({
      message,
      redirect,
      title,
    });
  }
  sendAnalytics(params: AnalyticsEventParams) {
    this.sendAnalytics(params);
  }
  setShareInformation(params: SetShareInformationParams) {
    this.setShareInformation(params);
  }
}
