import { PathInterpreter, PathInterpreterResultType } from '../../models';

/**
 * 요청 경로를 해석하여 실제 앱내에서 수행될 경로 유형을 알려준다.
 */
export class AppPathInterpreter implements PathInterpreter {
  interpret(path: string) {
    if (!path || typeof path !== 'string') {
      return PathInterpreterResultType.UNKNOWN;
    }
    try {
      if (/\/search\/filter\/?.*/.test(path)) {
        return PathInterpreterResultType.SEARCH_FILTER;
      }
    } catch (error) {
      //
    }
    return PathInterpreterResultType.UNKNOWN;
  }
}
