import React from 'react';
import styled from 'styled-components';

import { FixedImage } from '../Image';
import { LineClamp } from '../LineClamp';

export interface StoreCardProps {
  className?: string;
  imageId: string | number;
  title: string;
  href?: string;
  linkTarget?: string;
}

/**
 * 스토어 카드
 * 특정한 랜딩페이지가 정해져 있지 않아 범용적으로 사용됩니다.
 * @see {@link https://zpl.io/awKOgrN}
 */
export const StoreCard = ({
  className,
  imageId,
  title,
  href,
  linkTarget,
}: StoreCardProps) => {
  return (
    <div className={className}>
      <a href={href} target={linkTarget}>
        <FixedImage
          alt={title}
          css={`
            border-radius: 4px;
          `}
          height={180}
          imageId={imageId}
          width={320}
          lazy
        />
      </a>
      <StyledLineClamp fontSize="s18" lines={2} keepHeight>
        <a href={href}>{title}</a>
      </StyledLineClamp>
    </div>
  );
};

const StyledLineClamp = styled(LineClamp)`
  margin-top: 12px;
  font-weight: 700;
  white-space: pre-line;
`;
