import React from 'react';

import { Image, ImageProps } from './Image';
import { IMAGES_WIDTHS } from '../../constants';
import { staticURL } from '../../utils';

export interface FixedImageProps
  extends Omit<ImageProps, 'src' | 'srcSet' | 'sizes'> {
  imageId: number | string;
  width: typeof IMAGES_WIDTHS[number];
  height: number;
}

const RATIOS = [1, 2, 3];

/**
 * FixedImage는 고정된 크기의 이미지를 사용할 때 유용합니다.
 * {@link https://html.spec.whatwg.org/multipage/images.html#pixel-density-descriptor|pixel density descriptor}
 * (1x, 2x, 3x) unit을 사용합니다.
 */
export const FixedImage = ({
  alt,
  className,
  height,
  lazy,
  imageId,
  width,
}: FixedImageProps) => {
  // src는 srcset 미지원 브라우저에서 fallback으로 사용합니다. 최대한 큰 크기를 사용합니다.
  const srcRatio = RATIOS[RATIOS.length - 1];
  const src = staticURL(imageId, width * srcRatio, height * srcRatio);

  const srcSet = RATIOS.map(
    (ratio) => `${staticURL(imageId, width * ratio, height * ratio)} ${ratio}x`,
  ).join(', ');

  return (
    <Image
      alt={alt}
      className={className}
      height={height}
      lazy={lazy}
      src={src}
      srcSet={srcSet}
      width={width}
    />
  );
};
