import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '../Skeleton';

export const GoodsCardSkeleton: React.FC = () => {
  return (
    <>
      <StyledImageSkeleton />
      <StyledContent>
        <StyledNameSkeleton />
        <StyledNameSkeleton width="23%" />
        <StyledDescSkeleton />
      </StyledContent>
    </>
  );
};
const StyledImageSkeleton = styled(Skeleton)`
  padding-bottom: 100%;
  border-radius: 4px;
`;
const StyledContent = styled.div`
  margin-top: 5px;
`;
const StyledNameSkeleton = styled(Skeleton)`
  height: 15px;
  margin-bottom: 2px;
`;
const StyledDescSkeleton = styled(Skeleton)`
  width: 90px;
  height: 16px;
  margin-top: 10px;
`;
