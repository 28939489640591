export const pathnames = {
  article: /^\/articles\/.*/,
  articleEditor: /^\/article-editor.*/,
  articleViewer: /^\/articles\/\d+$/,
  articleViewerEvents: /^\/articles\/\d+\/events$/,
  articleViewerGoods: /^\/articles\/\d+\/goods$/,
  cs: /^\/cs\/.*/,
  feed: /^\/feed\/.*/,
  feedHome: /^\/feed\/home$/,
  goodsDetail: /^\/goods\/\d+$/,
  livetvDetail:
    /^\/livetv\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  meDormant: /^\/me\/dormant$/,
  meNotifications: /^\/me\/notifications$/,
  order: /^\/order\/.*/,
  orderHistory: /^\/order\/history$/,
  orderReturn: /^\/order\/return$/,
  privacy: /^\/privacy?\/?\S+$/,
  root: /^\/$/,
  search: /^\/search.*/,
  store: /^\/store$/,
  webviewPhoneAuthentication: /^\/webviews\/phone-authentication$/,
};
