import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

import { useUpdateUserProfile } from '@styleshare/analytics';
import { useQuery } from '@styleshare/react-query';

import { getMePhoneNumberVerified, getMeProfile } from '../apis';

export function useMeProfileService() {
  const queried = useQuery('@shared/me-profile', getMeProfile, {
    retry: false,
    staleTime: Infinity,
  });

  const { data, isFetched } = queried;
  const isSetSentry = useRef(false);

  useUpdateUserProfile({ profile: data });

  useEffect(() => {
    if (isFetched && !isSetSentry.current) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          country: data?.country,
          email: data?.email,
          id: data?.id ? data?.id.toString() : undefined,
          locale: data?.locale,
          username: data?.username,
        });
      });
      isSetSentry.current = true;
    }
  }, [data, isFetched]);

  return queried;
}

export function useMePhoneNumberVertified() {
  return useQuery(
    '@shared/me-phnoe-number-verifited',
    getMePhoneNumberVerified,
    {
      retry: false,
      staleTime: Infinity,
    },
  );
}
