import React from 'react';
import styled from 'styled-components';

import { GoodsPriceTextBox } from './GoodsPriceTextBox';
import { FixedImage } from '../Image';
import { LineClamp } from '../LineClamp';

export interface GoodsCardProps {
  className?: string;
  id: number;
  name: string;
  brandId: number;
  brandName: string;
  imageId: string | number;
  price: number;
  discountRate: number;
  linkTarget?: string;
  linkParams?: Record<string, string>;
}

/**
 * 상품 카드
 * @see {@link https://zpl.io/25WAoNW}
 */
export const GoodsCard: React.FC<GoodsCardProps> = ({
  id,
  name,
  brandId,
  brandName,
  imageId,
  price,
  discountRate,
  className,
  linkTarget,
  linkParams,
}) => {
  const queryString = React.useMemo(
    () => new URLSearchParams(linkParams).toString(),
    [linkParams],
  );

  return (
    <div className={className}>
      <a href={`/goods/${id}?${queryString}`} target={linkTarget}>
        <StyledFixedImage
          alt={name}
          height={320}
          imageId={imageId}
          width={320}
          lazy
        />
      </a>
      <BodyWrapper>
        <LineClamp fontSize="s14" lines={2} keepHeight>
          <Brand href={`/brands/${brandId}?${queryString}`}>{brandName}</Brand>
          &nbsp;
          <a href={`/goods/${id}?${queryString}`}>{name}</a>
        </LineClamp>
        <GoodsPriceTextBox discountRate={discountRate} price={price} />
      </BodyWrapper>
    </div>
  );
};

const StyledFixedImage = styled(FixedImage)`
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${({ theme }) => theme.color.black_03};
    content: '';
  }
`;

const BodyWrapper = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.fontSize.s14};
`;

const Brand = styled.a`
  font-weight: 700;
`;
