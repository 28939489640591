import { useCallback, useEffect } from 'react';
import { GoogleTagManagerParams } from '../models';
import { gtmScripts, sendDataLayer } from '../utils';

const DEFAULT_DATA_LAYER = 'dataLayer';

/**
 * GTM을 사용합니다.
 *
 * ```
 * useGTM({ gtmId: 'GTM-KVDDBJW', enabled: true or false (defualt true) });
 * ```
 * #### ===
 * ```
 * const { sendData } = useGTM();
 *
 * useEffect(() => {
 *   sendData({ event: 'test' });
 * });
 * ```
 * @param GoogleTagManagerParams
 */
export function useGTM(params?: GoogleTagManagerParams) {
  const {
    gtmId,
    dataLayerName = DEFAULT_DATA_LAYER,
    dataLayer,
    enabled = true,
  } = params ?? { gtmId: '' };

  const initialize = useCallback((params: GoogleTagManagerParams) => {
    if (!params.enabled) {
      return;
    }
    if (!params.gtmId) {
      return;
    }

    const { insertScript, insertNoScript } = gtmScripts(params);

    insertScript();
    insertNoScript();
  }, []);

  const sendData = useCallback(
    ({ $dataLayerName: customDataLayerName, ...data }: Record<string, any>) => {
      sendDataLayer({
        dataLayerName: customDataLayerName ?? dataLayerName,
        dataLayer: data,
      });
    },
    [],
  );

  useEffect(() => {
    if (!params) {
      return;
    }

    initialize({
      gtmId,
      dataLayerName,
      dataLayer,
      enabled,
    });
  }, [params?.gtmId, enabled, initialize]);

  return {
    initialize,
    sendData,
  };
}
