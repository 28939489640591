import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  discountRate?: number;
  price: number;
  showUnit?: boolean;
}

export const GoodsPriceTextBox: React.FC<Props> = ({
  className,
  discountRate,
  price,
  showUnit = true,
}) => {
  return (
    <StyledDiv className={className}>
      {!!discountRate && <DiscountRate>{discountRate}%</DiscountRate>}
      <span>
        {price.toLocaleString()}
        {/* TODO: 원 또는 won 으로 i18n 적용 */}
        {showUnit && '원'}
      </span>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.gray100};
  font-weight: 700;
`;

const DiscountRate = styled.span`
  margin-right: 3px;
  color: ${({ theme }) => theme.color.system_red};
`;
