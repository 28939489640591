import {
  differenceInMonths,
  format,
  formatDistanceToNowStrict,
} from 'date-fns';
import { ko } from 'date-fns/locale';

export function formatNumber(value: number, lang?: string): string {
  if (lang === 'ko-KR' || lang === 'ko') {
    return value > 9999
      ? (value / 10000).toFixed(value % 1000 > 10 ? 1 : 0) + '만'
      : value.toLocaleString();
  }

  return value > 999
    ? (value / 1000).toFixed(value % 100 > 10 ? 1 : 0) + 'k'
    : value.toLocaleString();
}

export function formatDatePolicy(createdAt: string): string {
  const dateCreatedAt = new Date(createdAt);
  const dateNow = new Date();

  if (differenceInMonths(dateNow, dateCreatedAt) > 11) {
    return format(dateCreatedAt, 'PPP', { locale: ko });
  }
  return `${formatDistanceToNowStrict(dateCreatedAt, { locale: ko })} 전`;
}
