export const grid = {
  cols_1: `grid-template-columns: repeat(1, minmax(0, 1fr));`,
  cols_2: `grid-template-columns: repeat(2, minmax(0, 1fr));`,
  cols_3: `grid-template-columns: repeat(3, minmax(0, 1fr));`,
  cols_4: `grid-template-columns: repeat(4, minmax(0, 1fr));`,
  cols_5: `grid-template-columns: repeat(5, minmax(0, 1fr));`,
  cols_6: `grid-template-columns: repeat(6, minmax(0, 1fr));`,
  cols_7: `grid-template-columns: repeat(7, minmax(0, 1fr));`,
  cols_8: `grid-template-columns: repeat(8, minmax(0, 1fr));`,
  cols_9: `grid-template-columns: repeat(9, minmax(0, 1fr));`,
  cols_10: `grid-template-columns: repeat(10, minmax(0, 1fr));`,
  cols_11: `grid-template-columns: repeat(11, minmax(0, 1fr));`,
  cols_12: `grid-template-columns: repeat(12, minmax(0, 1fr));`,
};
