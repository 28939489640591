export interface AppEnvironment {
  app: {
    environment: string;
  };
  isServer: boolean;
  production: boolean;
  stage: boolean;
  beta: boolean;
  commitHash: string;
  development: boolean;
  endPoint: {
    serverBaseUrl: string;
    apiBaseUrl: string;
    experimentS3ApiBaseUrl: string;
    hashtagApiBaseUrl: string;
    livetvApiBaseUrl: string;
    neoSearchApiBaseUrl: string;
    notificationApiBaseUrl: string;
    searchApiBaseUrl: string;
    shopGatewayApiBaseUrl: string;
    usercontentsApiBaseUrl: string;
    communityApiBaseUrl: string;
  };
  sdk: {
    kakao: string;
  };
}

const environment = process.env.NEXT_PUBLIC_ENV || '';

const isServer = typeof window === 'undefined';

const isDevelopment = environment === 'development';
const isStage = environment === 'stage';
const isBeta = environment === 'beta';
const isProduction = environment === 'production';

const devProxyPath = (proxyPath: string, apiBaseUrl: string) => {
  if (isDevelopment) {
    return isServer ? `http://127.0.0.1:3000${proxyPath}` : proxyPath;
  } else {
    return apiBaseUrl;
  }
};

const serverBaseUrl = process.env.NEXT_PUBLIC_SERVER_BASE_URL || '';
const apiBaseUrl = devProxyPath(
  '/api',
  process.env.NEXT_PUBLIC_API_BASE_URL || '',
);
const experimentS3ApiBaseUrl =
  process.env.NEXT_PUBLIC_EXPERIMENT_S3_API_BASE_URL || '';
const hashtagApiBaseUrl = devProxyPath(
  '/hashtag',
  process.env.NEXT_PUBLIC_HASHTAG_API_BASE_URL || '',
);
const livetvApiBaseUrl = devProxyPath(
  '/livetv',
  process.env.NEXT_PUBLIC_LIVETV_API_BASE_URL || '',
);
const neoSearchApiBaseUrl = devProxyPath(
  '/neo-search',
  process.env.NEXT_PUBLIC_NEO_SEARCH_API_BASE_URL || '',
);
const notificationApiBaseUrl = devProxyPath(
  '/notification',
  process.env.NEXT_PUBLIC_NOTIFICATION_API_BASE_URL || '',
);
const searchApiBaseUrl = devProxyPath(
  '/search',
  process.env.NEXT_PUBLIC_SEARCH_API_BASE_URL || '',
);
const shopGatewayApiBaseUrl = devProxyPath(
  '/shop-gateway',
  process.env.NEXT_PUBLIC_SHOP_GATEWAY_API_BASE_URL || '',
);
const usercontentsApiBaseUrl = devProxyPath(
  '/usercontents',
  process.env.NEXT_PUBLIC_USERCONTENTS_BASE_URL || '',
);
const communityApiBaseUrl = process.env.NEXT_PUBLIC_COMMUNITY_BASE_URL || '';

const kakaoSdkKey = process.env.NEXT_PUBLIC_KAKAO_SDK_KEY || '';
const commitHash = process.env.COMMIT_HASH || '';

export const env: AppEnvironment = {
  app: {
    environment,
  },
  beta: isBeta,
  commitHash,
  development: isDevelopment,
  endPoint: {
    apiBaseUrl,
    communityApiBaseUrl,
    experimentS3ApiBaseUrl,
    hashtagApiBaseUrl,
    livetvApiBaseUrl,
    neoSearchApiBaseUrl,
    notificationApiBaseUrl,
    searchApiBaseUrl,
    serverBaseUrl,
    shopGatewayApiBaseUrl,
    usercontentsApiBaseUrl,
  },
  isServer,
  production: isProduction,
  sdk: {
    kakao: kakaoSdkKey,
  },
  stage: isStage,
};
