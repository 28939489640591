import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

import { breakpoint } from '@styleshare/styled';

import {
  isMobile as getIsMobile,
  isNativeApp as getIsNative,
} from '../utils/userAgent';

export const DeviceDetectContext = createContext({
  mobile: getIsMobile(),
  native: getIsNative(),
});

interface Props {
  userAgent?: string;
}

/**
 * 컨텍스트: UserAgent 및 Resizing 여부에 따른 태블릿/모바일 여부를 판별 해 준다.
 * 지정된 곳 이외에서는 사용치 않는다.
 *
 * @see https://stackoverflow.com/questions/29046324/whats-the-most-reliable-way-to-integrate-javascript-with-media-queries
 * @see https://jsperf.com/matchmedia-vs-resize/3
 */
export const DeviceDetectProvider: FC<Props> = ({ userAgent, children }) => {
  const [isMobile, setIsMobile] = useState(getIsMobile(userAgent));
  const isNative = useMemo(() => getIsNative(userAgent), [userAgent]);
  const hasWindow = typeof window !== 'undefined';

  useEffect(() => {
    setIsMobile(getIsMobile(userAgent));
  }, [userAgent]);

  useEffect(() => {
    if (hasWindow) {
      const mediaQuery = `screen and (max-width: ${breakpoint[3] - 1}px)`;
      let matchMedia: MediaQueryList | null = null;
      if (window?.matchMedia) {
        matchMedia = window.matchMedia(mediaQuery);
      }

      const handleResizeForMobile = (e: MediaQueryListEvent) => {
        setIsMobile(e.matches);
      };

      const initialWidthCheck = () => {
        if (window.innerWidth < breakpoint[3] - 1) {
          setIsMobile(true);
        }
      };

      initialWidthCheck();
      if (matchMedia?.addEventListener) {
        matchMedia.addEventListener('change', handleResizeForMobile);
      }
      return () => {
        if (matchMedia?.removeEventListener) {
          matchMedia.removeEventListener('change', handleResizeForMobile);
        }
      };
    }
  }, [hasWindow]);

  return (
    <DeviceDetectContext.Provider
      value={{
        mobile: isMobile,
        native: isNative,
      }}
    >
      {children}
    </DeviceDetectContext.Provider>
  );
};
