import { createGlobalStyle } from 'styled-components';

/**
 * @description frontend에 적용되어 있는 글로벌 룰.
 * 이하의 개별항목 확인 후에 제거할 필요가 있음.
 */
export const LegacyGlobalStyle = createGlobalStyle`
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  /*
   * 마우스로 포커스 할 때만 focus ring을 숨기고 키보드로 포커스 하면 브라우저 기본 focus 스타일을 볼 수 있음
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  
  svg {
    display: inline;
  }

  button,
  input,
  optgroup,
  textarea,
  select {
    padding: 0;
    color: inherit;
    line-height: inherit;
    background-color: transparent;
    border: 0;
  }

  a {
    text-decoration: none;
  }

  a,
  button {
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    object-fit: cover;
    object-position: center;
  }
`;
