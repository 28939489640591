import type { MutableRefObject, RefCallback } from 'react';

type Ref<T> = RefCallback<T | null> | MutableRefObject<T | null>;
export type CombinedRefs<T> = (node: T | null) => void;

/**
 * ref를 여러개 적용하고 싶을 때...
 *
 * @param refs
 */
export function combineRefs<T = HTMLElement>(...refs: Ref<T>[]) {
  return (node: T | null): void => {
    refs.forEach((ref) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(node);
        } else {
          ref.current = node;
        }
      }
    });
  };
}
