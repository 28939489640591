import { useEffect } from 'react';

import {
  useAnalyticsEvent,
  UseAnalyticsEventParams,
} from './useAnalyticsEvent';
import { useDeviceDetect } from './useDeviceDetect';

export type UseAnalytcsPageViewEventParams = UseAnalyticsEventParams;

/**
 * 특정 페이지 진입 시 이벤트를 기록합니다.
 * 웹뷰라면 document visibility가 변화했을 때에도 기록합니다.
 */
export function useAnalyticsPageViewEvent(
  params: UseAnalytcsPageViewEventParams,
) {
  const track = useAnalyticsEvent(params);

  const { native } = useDeviceDetect();

  useEffect(() => {
    track();

    if (!native) return;

    const callback = () => {
      if (document && document.visibilityState === 'visible') {
        track();
      }
    };

    document.addEventListener('visibilitychange', callback, false);

    return () => {
      document.removeEventListener('visibilitychange', callback, false);
    };
  }, [native, track]);
}
