import React from 'react';

import { Image, ImageProps } from './Image';
import { IMAGES_WIDTHS } from '../../constants';
import { staticURL } from '../../utils';

export interface LegacyResponsiveImageProps
  extends Omit<ImageProps, 'src' | 'srcSet' | 'width' | 'height'> {
  imageId: number | string;
  widthRatio: number;
  heightRatio: number;
}

/**
 * LegacyResponsiveImage는 screen size에 따라 알맞은 크기의 이미지를 보여줘야 할 때 유용합니다.
 * {@link https://html.spec.whatwg.org/multipage/images.html#width-descriptor|width descriptor}
 * (w) unit을 사용합니다.
 */
export function LegacyResponsiveImage({
  alt,
  className,
  lazy,
  sizes,
  imageId,
  widthRatio,
  heightRatio,
}: LegacyResponsiveImageProps) {
  const ratio = heightRatio / widthRatio;
  // src는 srcset 미지원 브라우저에서 fallback으로 사용합니다. 미지원 브라우저라도 스크린의 해상도가 클 수도 있기 때문에 최대한 큰 크기의 이미지를 사용합니다.
  const srcWidth = IMAGES_WIDTHS[IMAGES_WIDTHS.length - 1];
  const src = staticURL(imageId, srcWidth, Math.ceil(srcWidth * ratio));

  const srcSet = IMAGES_WIDTHS.map(
    (width) =>
      `${staticURL(imageId, width, Math.ceil(width * ratio))} ${width}w`,
  ).join(', ');

  return (
    <Image
      alt={alt}
      className={className}
      height={heightRatio}
      lazy={lazy}
      sizes={sizes}
      src={src}
      srcSet={srcSet}
      width={widthRatio}
    />
  );
}
