import { AlertArgs, NativeAppService } from '../../models';

const noop = () => {
  // noop
};
/**
 * 더미 앱 서비스.
 *
 * 네이티브앱 서비스를 구동할 수 있는 환경이 아님에도 기능 호환성을 위해 가짜 서비스를 주어야 할 경우에 쓰인다.
 *
 * 관련 메서드는 똑같이 주어지지만, 아무런 기능도 하지 않는다.
 */
export class DummyAppService implements NativeAppService {
  changeSearchResultTab = noop;
  collectStyle = noop;
  stopLoading = noop;
  openNewWebview = noop;
  destroy = noop;
  sendAnalytics = noop;
  selectServiceHomeAsDefaultTab = noop;
  setShareInformation = noop;
  updateArticleCommentPreview = noop;
  updateArticleLike = noop;
  showAlert = ({ message }: AlertArgs) => alert(message);
}
