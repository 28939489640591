import { env } from '~core';
import { isIOS, isNativeApp } from '~modules/@shared/utils';

import { AndroidAppService } from './AndroidAppService';
import { DummyAppService } from './DummyAppService';
import { IOSAppService } from './IOSAppService';
import { NativeAppService } from '../../models';

let appService: NativeAppService | null = null;

/**
 * 네이티브앱 서비스를 만드는 팩토리
 */
export function nativeAppFactory(): NativeAppService {
  if (appService) {
    appService.destroy();
  }
  if (!isNativeApp() || env.isServer) {
    return new DummyAppService();
  }
  if (isIOS()) {
    return (appService = new IOSAppService());
  }
  return (appService = new AndroidAppService());
}
