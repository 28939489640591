import { TRANSITION_DURATION } from './toastConstant';
import { ActionType, dispatch } from './toastStore';
import { ToastModel, ToastOptions } from './toastTypes';

const generateId = (() => {
  let count = 0;
  return () => {
    return (++count).toString();
  };
})();

const createTimeouts = new Map<
  ToastModel['id'],
  ReturnType<typeof setTimeout>
>();

const addToCreateQueue = (toastId: string) => {
  if (createTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    createTimeouts.delete(toastId);
    dispatch({
      toastId: toastId,
      type: ActionType.ADD_TOAST_DONE,
    });
  }, TRANSITION_DURATION);

  createTimeouts.set(toastId, timeout);
};

const removeTimeouts = new Map<
  ToastModel['id'],
  ReturnType<typeof setTimeout>
>();

const addToRemoveQueue = (toastId: string) => {
  if (removeTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    removeTimeouts.delete(toastId);
    dispatch({
      toastId: toastId,
      type: ActionType.REMOVE_TOAST_DONE,
    });
  }, TRANSITION_DURATION);

  removeTimeouts.set(toastId, timeout);
};

const createToast = (message: string, options: ToastOptions): ToastModel => ({
  autoDismiss: false,
  createdAt: Date.now(),
  id: generateId(),
  message,
  pauseDuration: 0,
  status: 'appearing',
  type: 'default',
  ...options,
});

const addToast = (message: string, options: ToastOptions = {}) => {
  const toast = createToast(message, options);
  dispatch({ toast, type: ActionType.ADD_TOAST });
  addToCreateQueue(toast.id);
  return toast.id;
};

const removeToast = (toastId: string) => {
  dispatch({
    toastId,
    type: ActionType.REMOVE_TOAST,
  });
  addToRemoveQueue(toastId);
};

export { addToast, removeToast };
