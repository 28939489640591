import React, { useRef } from 'react';
import * as Sentry from '@sentry/react';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import { Hydrate } from 'react-query/hydration';
import { Provider as ReackitProvider } from 'reakit';
import { ThemeProvider } from 'styled-components';

import {
  createQueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from '@styleshare/react-query';
import { GlobalStyle, NprogresStyle, theme } from '@styleshare/styled';
import { env } from '~core/env';
import { Toast } from '~modules/@shared/components';
import { DeviceDetectProvider } from '~modules/@shared/contexts';

import { useNprogress } from '../modules/@shared/hooks/useNprogress';
import { DefaultHead } from '../modules/@shared/layouts/DefaultHead';

const LazyReactQueryDevtools = dynamic(
  () =>
    import('@styleshare/react-query').then(
      (module) => module.ReactQueryDevtools,
    ),
  { ssr: false },
) as typeof ReactQueryDevtools;

interface PageProps extends SSRConfig {
  dehydratedState: unknown;
  userAgent: string;
}
interface Props extends AppProps<PageProps> {
  pageProps: PageProps;
}

const App = ({ Component, pageProps }: Props) => {
  useNprogress();

  const queryClientRef = useRef(createQueryClient());

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return (
          <>
            <div>잠시 후 다시 시도해주세요.</div>
            <div>{error.toString()}</div>
          </>
        );
      }}
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClientRef.current}>
          <GlobalStyle />
          <NprogresStyle />
          <Hydrate state={pageProps.dehydratedState}>
            <ReackitProvider>
              <DeviceDetectProvider userAgent={pageProps.userAgent}>
                <DefaultHead />
                <Component {...pageProps} />
              </DeviceDetectProvider>
            </ReackitProvider>
          </Hydrate>
          <Toast />
          {env.development && <LazyReactQueryDevtools />}
        </QueryClientProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

// FIXME: 타입 에러 발생하는데 어떻게 해결할 수 있을 지 모르겠음
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(App);
