import { rgba } from '../polished';
import { css } from 'styled-components';

export const darkenHover = css`
  &:active::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: ${(props) => rgba(props.theme.color.gray100, 0.2)};
    content: '';
  }
`;
