import React from 'react';
import styled from 'styled-components';

import { ToastBase } from './ToastBase';
import type { ToastStatus } from './toastTypes';
import { LineClamp } from '../LineClamp';

interface Props {
  status: ToastStatus;
  children?: React.ReactNode;
  className?: string;
  marginBottom?: number;
}

export const ToastDefault = ({
  status,
  children,
  className,
  marginBottom,
}: Props) => {
  return (
    <StyledToastBase
      className={className}
      marginBottom={marginBottom}
      status={status}
    >
      <StyledLineClamp fontSize="s14" lines={1}>
        {children}
      </StyledLineClamp>
    </StyledToastBase>
  );
};

const StyledToastBase = styled(ToastBase)`
  height: 34px;
`;

const StyledLineClamp = styled(LineClamp)`
  flex: 1;
  min-width: 84px;
  padding: 0 12px;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.s14};
`;
