import copy from 'copy-to-clipboard';
import { ParsedUrlQueryInput, stringify } from 'querystring';

export interface DownloadImageFromUrlProps {
  url: string;
}

export const downloadImageFromUrl = ({ url }: DownloadImageFromUrlProps) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url;
  document.body.appendChild(a);
  a.click();

  setTimeout(function () {
    document.body.removeChild(a);
  }, 100);
};

export interface copyLocationProps {
  message: string;
  title?: string;
  url?: string;
}
export const copyLocation = ({
  title: _title,
  message,
  url: _url,
}: copyLocationProps) => {
  const url = _url ?? location.href;
  const title = _title ?? document.title;

  if (url) {
    if (typeof navigator.share !== 'undefined') {
      navigator.share({
        title,
        url,
      });
    } else {
      copy(url, {
        message,
      });
      alert(message);
    }
  }
};

interface ShareTwitterProps {
  url: string;
  text?: string;
  hashtags?: string;
}
export const shareTwitter = ({ url, text, hashtags }: ShareTwitterProps) => {
  let link = 'https://twitter.com/intent/tweet?url=' + url;
  if (text) {
    link += '&text=' + encodeURIComponent(text + '\n\n');
  }
  if (hashtags) {
    link += '&hashtags=' + hashtags;
  }
  window.open(link);
};

interface ShareFacebookProps {
  url: string;
}
/**
 * @description 기타 정보는 og 태그 기준으로 적용됨
 */
export const shareFacebook = ({ url }: ShareFacebookProps) => {
  window.open('http://www.facebook.com/sharer/sharer.php?u=' + url);
};

export const getShareUrl = (utmTerm: string) => {
  const shareParams = [
    'utm_source=styleshare_event_' + location.pathname.split('/').slice(-1)[0],
    'utm_medium=share_button',
    'utm_campaign=share_sns',
    'utm_term=' + utmTerm,
  ];

  return (
    location.href.replace(location.search, '').replace(location.hash, '') +
    '?' +
    shareParams.join('&')
  );
};
interface GetUtmParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
}
export const getUtmParams = (params: Partial<GetUtmParams>) =>
  stringify(params as ParsedUrlQueryInput);
