import React, { ComponentProps } from 'react';
import styled from 'styled-components';

import { fontSize } from '@styleshare/styled';

import { AdaptiveLink } from '../AdaptiveLink';
import { FixedImage } from '../Image';
import { LineClamp } from '../LineClamp';

interface Props
  extends Pick<
    ComponentProps<typeof AdaptiveLink>,
    'appHref' | 'href' | 'to' | 'onClick'
  > {
  alt?: string;
  bold?: React.ReactNode;
  fontSize?: keyof typeof fontSize;
  children?: React.ReactNode;
  className?: string;
  imageId: string | number;
}

/**
 * 이미지 비율이 3:2인 이벤트 카드입니다.
 *
 * 이벤트 카드에는 여러 타입이 있을 수 있습니다.
 * 기획전, 콜렉션, 아티클 등..
 * 필요에 따라 다음의 컴포넌트를 이용해 별도의 카드를 만들어서 사용하는 게 좋을 것 같습니다.
 *
 * @see {@link https://zpl.io/VxzeJ0X}
 */
export const EventCard = ({
  alt = '',
  bold,
  className,
  fontSize = 's13',
  children,
  imageId,
  ...linkProps
}: Props) => {
  return (
    <AdaptiveLink {...linkProps} className={className}>
      <StyledFixedImage
        alt={alt}
        height={134}
        imageId={imageId}
        width={200}
        lazy
      />
      <StyledTextBox fontSize={fontSize} lines={2} keepHeight>
        {!!bold && <StyledBold>{bold}</StyledBold>}
        {children}
      </StyledTextBox>
    </AdaptiveLink>
  );
};

const StyledBold = styled.span`
  margin-right: 3px;
  font-weight: bold;
`;

const StyledFixedImage = styled(FixedImage)`
  border-radius: ${({ theme }) => theme.radius.box};
`;

const StyledTextBox = styled(LineClamp)`
  margin: 7px 0 8px;
  color: ${({ theme }) => theme.color.gray100};
`;
