import React from 'react';
import { Portal } from 'reakit';
import styled from 'styled-components';

import { ToastDefault } from './ToastDefault';
import { ToastSnackbar } from './ToastSnackbar';
import { removeToast } from './toastUtil';
import { useToast } from './useToast';

export const Toast = () => {
  const { toasts, handlers } = useToast();

  return (
    <Portal>
      <Wrapper
        onMouseEnter={handlers.startPause}
        onMouseLeave={handlers.endPause}
      >
        {toasts.map((toast) => {
          switch (toast.type) {
            case 'snackbar':
              return (
                <ToastSnackbar
                  key={toast.id}
                  button={toast.button}
                  href={toast.href}
                  marginBottom={toast.marginBottom}
                  status={toast.status}
                  onButtonClick={(event) => {
                    toast.onButtonClick?.(event);
                    removeToast(toast.id);
                  }}
                >
                  {toast.message}
                </ToastSnackbar>
              );
            default:
              return (
                <ToastDefault
                  key={toast.id}
                  marginBottom={toast.marginBottom}
                  status={toast.status}
                >
                  {toast.message}
                </ToastDefault>
              );
          }
        })}
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  pointer-events: none;

  @supports (padding: max(0px)) {
    bottom: max(0px, constant(safe-area-inset-bottom));
    bottom: max(0px, env(safe-area-inset-bottom));
  }
`;
