import React from 'react';
import Player from 'react-player/lazy';
import styled from 'styled-components';

import { useIntersectionObserver } from '@styleshare/hooks';

import { AspectRatio } from '../AspectRatio';

type Props = {
  className?: string;
  muted?: boolean;
  /**
   * 썸네일 이미지
   */
  poster?: string;
  /**
   * 비디오 주소
   */
  src: string;
  /**
   * 원본 높이
   */
  originalHeight: number;
  /**
   * 원본 너비
   */
  originalWidth: number;
};

/**
 * 비디오가 필요한 곳에서 사용하려고 만듦..
 * 스타일 카드나 상품 카드에서 이미지를 보여줄 때 사용하려고 합니다.
 *
 * @see {@link https://zpl.io/2vv8Y5r}
 */
export const Video = ({
  className,
  muted,
  poster,
  src,
  originalHeight,
  originalWidth,
}: Props) => {
  const [playing, setPlaying] = React.useState(false);
  const [subscribe] = useIntersectionObserver<HTMLDivElement>(([entry]) => {
    if (!entry) return;

    setPlaying(entry.isIntersecting);
  });

  return (
    <div ref={subscribe}>
      <StyledAspectRatio
        className={className}
        heightRatio={originalHeight}
        widthRatio={originalWidth}
      >
        <StyledVideo
          config={{
            file: {
              attributes: {
                poster,
                preload: 'metadata',
              },
            },
          }}
          height="100%"
          muted={muted}
          playing={playing}
          url={src}
          width="100%"
          loop
          playsinline
        />
      </StyledAspectRatio>
    </div>
  );
};

const StyledAspectRatio = styled(AspectRatio)`
  overflow: hidden;
  /**
   * 로딩 중일 때 이미지 영역에 배경색을 보여줍니다.
   */
  background-color: ${({ theme }) => theme.color.gray10};
  /**
   * Safari에서 border-radius + overflow: hidden 이슈를 해결합니다.
   * https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
   */
  mask-image: -webkit-radial-gradient(white, black);
`;

export const StyledVideo = styled(Player)`
  background-color: ${({ theme }) => theme.color.white};
`;
