import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children?: React.ReactNode;
  label?: string;
}

export const SwiperWrapper = ({ className, children, label }: Props) => {
  return (
    <StyledSection aria-label={label} className={className}>
      {children}
    </StyledSection>
  );
};

const StyledSection = styled.section`
  position: relative;
`;
