/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Link, { LinkProps } from 'next/link';

import { useDeviceDetect } from '../../hooks';

interface Props
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    Omit<LinkProps, 'href' | 'onMouseEnter'> {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  to?: string;
  /**
   * 인앱 환경일 때 deep-link 를 수행하고 싶다면 설정한다.
   *
   * 만약 주소 접두어가 `stsh://` 혹은 `stsh-web://` 이 아니라면 인앱 새창으로 동작된다.
   *
   * 새창 모드일 때 appTarget props 에 지정된 값을 넣으면 관련 기능으로 동작 된다.
   *
   * 일반 웹 환경이면 설정 내용은 무시 된다.
   */
  appHref?: string;
  /**
   * 웹뷰 새창을 열 때 적용 할 제목.
   *
   * 일반적인 DOM 의 title 역할은 하지 않는다.
   */
  title?: string;
  rel?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * 적응형 링크를 렌더링한다.
 *
 * 여기서의 적응형이란 네이티브앱 환경과 웹브라우저 환경에 대한 자동 렌더링 상황을 말한다.
 *
 * 사용되는 주요 속성은 다음과 같다. 아래로 갈 수록 우선순위가 낮다.
 * - appHref - 인앱 deep-link 용. 현재 화면에서 다른 앱 화면을 호출하며 네이티브앱 환경에서만 유효하다.
 *   - `stsh://` 로 시작하는 주소라면 딥링크로, 그 외는 앱내 새창을 열기위한 주소로 인식된다.
 * - to - next/link 의 Link 컴포넌트의 href 것과 동일. 기본 웹환경에서만 쓰이나 appHref 미설정 시 어디서든 사용된다.
 * - href - a 태그에 쓰이는 하이퍼링크 주소. 기본 웹환경에서만 쓰이나 to, appHref 설정이 안되어 있다면, 어디서든 쓰이게 된다.
 *
 * @example
 * // react-router 와 deep-link 를 함께 쓸 때 예시
 * const Section: FC = () => {
 *   return (
 *     <div>
 *       <AdaptiveLink
 *         to="/goods/1234"
 *         appHref="stsh://goods/1234"
 *       />
 *         Go To Anywhere~
 *       </AdaptiveLink>
 *     </div>
 *   );
 * };
 * * // 일반 href 와 인앱 새창열기를 함께 쓸 때 예시
 * const Section: FC = () => {
 *   return (
 *     <div>
 *       <AdaptiveLink
 *         href="https://www.google.com"
 *         appHref="/search/filter"
 *       />
 *         Go To Anywhere~
 *       </AdaptiveLink>
 *     </div>
 *   );
 * };
 *
 * @see https://reactrouter.com/web/api/Link
 *
 */
export const AdaptiveLink = ({
  className,
  href,
  appHref,
  to,
  replace,
  title,
  rel,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  onClick,
  children,
  ...restProps
}: Props) => {
  const { native } = useDeviceDetect();

  if (native && appHref) {
    return (
      <a
        className={className}
        href={appHref}
        rel={rel}
        onClick={onClick}
        {...restProps}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        href={to}
        locale={locale}
        passHref={passHref}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
      >
        <a className={className} rel={rel} onClick={onClick} {...restProps}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <a
      className={className}
      href={href}
      rel={rel}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </a>
  );
};
