import { UserAgent } from './useragent.util';

const WEBVIEW_CLOSE_SCHEME = 'stsh://close';

/**
 * 웹/네이티브 알럿 메시지를 출력합니다.
 */
interface ShowAlert {
  message: string;
  title?: string;
  redirect?: string;
}
export const showAlert = ({
  message,
  title = 'StyleShare',
  redirect,
}: ShowAlert) => {
  try {
    const { isFromApp } = new UserAgent();
    if (isFromApp) {
      const textComponent = encodeURIComponent(message);
      const titleComponent = encodeURIComponent(title);
      const redirectComponent = redirect ? encodeURIComponent(redirect) : '';
      if (redirect?.includes(WEBVIEW_CLOSE_SCHEME)) {
        alert(message);
        window.location.href = WEBVIEW_CLOSE_SCHEME;
        return;
      }

      window.location.href =
        `stsh://alert?message=${textComponent}&title=${titleComponent}` +
        (redirect ? `&redirect=${redirectComponent}` : '');
    } else {
      alert(message);
      if (redirect) {
        window.location.href = redirect;
      }
    }
  } catch (error) {
    //
  }
};
