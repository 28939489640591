import { useCallback, useState } from 'react';

export function useFocused(): [boolean, { onBlur(): void; onFocus(): void }] {
  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return [focused, { onBlur, onFocus }];
}
