import React, { useMemo } from 'react';

import { SwiperSlide } from './SwiperSlide';
import { SwiperTrack } from './SwiperTrack';
import { SwiperWrapper } from './SwiperWrapper';
import { SwiperStateReturn } from './useSwiper';

export interface SwiperProps extends SwiperStateReturn {
  children?: React.ReactNode;
  className?: string;
  label?: string;
}

export const Swiper = ({
  children,
  className,
  label,
  autoplay,
  gap,
  peek,
  slidesPerView,
  getSlideStyle,
  trackRef,
}: SwiperProps) => {
  const length = useMemo(() => React.Children.count(children), [children]);

  return (
    <SwiperWrapper className={className} label={label}>
      <SwiperTrack ref={trackRef} aria-live={autoplay ? 'polite' : 'off'}>
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            gap={gap}
            label={`${index + 1} / ${length}`}
            peek={peek}
            slidesPerView={slidesPerView}
            style={getSlideStyle(index)}
          >
            {child}
          </SwiperSlide>
        ))}
      </SwiperTrack>
    </SwiperWrapper>
  );
};
