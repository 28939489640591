import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';

import {
  useResizeObserver,
  useResizeObserverChildren,
} from '~modules/@shared/hooks/useResizeObserver';

import { initialSwiperState, swiperReducer } from './swiperReducer';
import {
  findRawSlide,
  makeSlides,
  passiveFalseIfSupported,
} from './swiperUtil';

export interface UseSwiperOptions {
  /**
   * 슬라이드 자동으로 넘기기
   */
  autoplay?: number | false;
  /**
   * 슬라이드 사이의 간격 (px)
   */
  gap?: number;
  /**
   * 다음 슬라이드가 살짝 보이는 여백 크기 (px)
   */
  peek?: number;
  /**
   * 무한 반복 캐러셀 사용
   */
  loop?: boolean;
  /**
   * 슬라이드 크기 reponsive 사용
   */
  slidesPerView?: number;
  onSwipeEnd?: () => void;
}

const TRANSITION_DURATION = 250;

export const useSwiper = (options: UseSwiperOptions = {}) => {
  const { autoplay = false, gap = 0, loop = false, onSwipeEnd } = options;
  const [trackRef, setTrackRef] = useState<HTMLDivElement | null>(null);

  const [state, dispatch] = useReducer(swiperReducer, initialSwiperState);

  const trackRect = useResizeObserver(trackRef);
  const slideRects = useResizeObserverChildren(trackRef);

  const trackWidth = useMemo(() => trackRect.width, [trackRect]);
  const slideWidths = useMemo(
    () => slideRects.map((slideRect) => slideRect.width),
    [slideRects],
  );
  const slides = useMemo(
    () => makeSlides(slideWidths, gap),
    [gap, slideWidths],
  );

  const allSlidesWidth = useMemo(() => {
    if (slides.length === 0) {
      return 0;
    }

    return slides[slides.length - 1].xEnd;
  }, [slides]);

  const lastSnapPoint = useMemo(
    () => Math.max(allSlidesWidth, trackWidth) - trackWidth,
    [allSlidesWidth, trackWidth],
  );

  // 스와이프 핸들러
  useEffect(() => {
    const trackElement = trackRef;

    if (!trackElement) {
      return;
    }

    let currentX = 0;
    let currentY = 0;
    let dx = 0;
    let dy = 0;
    let vx = 0;
    let timestamp = Date.now();
    let moved = false;
    let shouldStartSwipe = false;

    const handleSwipeStart = (x: number, y: number) => {
      currentX = x;
      currentY = y;
      dx = 0;
      dy = 0;
      vx = 0;
      timestamp = Date.now();
      moved = false;
      shouldStartSwipe = false;
    };

    const handleSwipeMove = (event: Event, x: number, y: number) => {
      // touchmove 이벤트 직전의 x좌표와 touchmove 이벤트의 x좌표의 차를 구해서
      // 몇 픽셀을 움직였는지 계산합니다.
      dx = x - currentX;
      dy = y - currentY;

      // 가속도를 계산합니다.
      const now = Date.now();
      const dt = now - timestamp;
      vx = dx / dt;

      // 스와이프하는 것이라고 추정합니다. 스크롤 이벤트를 막습니다.
      if (Math.abs(dx) >= Math.abs(dy)) {
        event.preventDefault();
      }

      if (!moved && (Math.abs(dx) !== 0 || Math.abs(dy) !== 0)) {
        shouldStartSwipe = Math.abs(dx) > Math.abs(dy);
        moved = true;
      }

      if (shouldStartSwipe) {
        // 실제로 스와이프를 하는 중입니다. 스크롤 이벤트를 막습니다.
        event.preventDefault();

        dispatch({
          payload: {
            dx,
            gap,
            lastSnapPoint,
            loop,
            slides,
          },
          type: 'swipeMove',
        });
      }

      // 다음 touchmove 이벤트를 대비하기 위해 초기화합니다.
      currentX = x;
      currentY = y;
      dx = 0;
      dy = 0;
      timestamp = now;
    };

    const handleSwipeEnd = () => {
      if (shouldStartSwipe) {
        setTimeout(() => {
          dispatch({
            payload: {
              gap,
              lastSnapPoint,
              loop,
              slides,
              vx,
            },
            type: 'swipeEnd',
          });
        }, 16);

        onSwipeEnd?.();
      }
    };

    const handleTouchStart = (event: TouchEvent) => {
      handleSwipeStart(event.touches[0].pageX, event.touches[0].pageY);
    };

    const handleTouchMove = (event: TouchEvent) => {
      handleSwipeMove(event, event.touches[0].pageX, event.touches[0].pageY);
    };

    const handleTouchEnd = () => {
      handleSwipeEnd();
    };

    const handleMouseDown = (event: MouseEvent) => {
      handleSwipeStart(event.pageX, event.pageY);

      const handleMouseMove = (event: MouseEvent) => {
        // 캐러셀의 이미지나 텍스트가 드래그 되지 않도록 합니다.
        event.preventDefault();

        handleSwipeMove(event, event.pageX, event.pageY);
      };

      const handleMouseUp = () => {
        handleSwipeEnd();

        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };

      const handleClick = (event: MouseEvent) => {
        if (moved) {
          event.preventDefault();
        }

        window.removeEventListener('click', handleClick);
      };

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('click', handleClick);
    };

    trackElement.addEventListener('touchstart', handleTouchStart);
    trackElement.addEventListener(
      'touchmove',
      handleTouchMove,
      passiveFalseIfSupported,
    );
    trackElement.addEventListener('touchend', handleTouchEnd);
    trackElement.addEventListener('mousedown', handleMouseDown);

    return () => {
      trackElement.removeEventListener('touchstart', handleTouchStart);
      trackElement.removeEventListener('touchmove', handleTouchMove);
      trackElement.removeEventListener('touchend', handleTouchEnd);
      trackElement.removeEventListener('mousedown', handleMouseDown);
    };
  }, [gap, lastSnapPoint, loop, onSwipeEnd, slides, trackRef]);

  useEffect(() => {
    if (state.status !== 'transitioning') {
      return;
    }

    const timeout = setTimeout(() => {
      dispatch({
        payload: { lastSnapPoint, loop, slides },
        type: 'transitionEnd',
      });
    }, TRANSITION_DURATION + 16);

    return () => clearTimeout(timeout);
  }, [lastSnapPoint, loop, slides, state.status]);

  const getSlideStyle = useCallback(
    (index: number): React.CSSProperties => {
      // ssr 지원
      if (typeof window === 'undefined' || slides.length === 0) {
        return {
          transform: 'translate3d(0px, 0, 0)',
        };
      }

      const compareX = state.status === 'idle' ? state.x : state.rawX;
      let x = state.status === 'swiping' ? state.rawX : state.x;
      let shouldReversed = false;

      const rawSlide = findRawSlide(slides, gap, index);

      // loop 모드라면 슬라이드를 반대쪽으로 이동시켜야 할지 계산합니다.
      if (loop) {
        if (slides[slides.length - 1].xStart <= compareX) {
          shouldReversed = rawSlide.xEnd < compareX - gap;
        } else if (slides[0].xEnd >= compareX) {
          shouldReversed =
            rawSlide.xEnd - slides[slides.length - 1].xEnd > compareX;
        }
      }

      if (shouldReversed) {
        if (compareX > 0) {
          x = x - (slides[slides.length - 1].xEnd + gap);
        } else {
          x = x + (slides[slides.length - 1].xEnd + gap);
        }
      }

      return {
        transform: `translate3d(${-x}px, 0, 0)`,
        transition:
          state.status === 'transitioning'
            ? `transform ${TRANSITION_DURATION}ms ease-out`
            : `transform 0ms ease-out`,
      };
    },
    [gap, loop, slides, state.rawX, state.status, state.x],
  );

  const moveTo = useCallback(
    (index: number) => {
      dispatch({
        payload: { gap, lastSnapPoint, loop, rawIndex: index, slides },
        type: 'transitionStart',
      });
    },
    [gap, lastSnapPoint, loop, slides],
  );

  const moveBy = useCallback(
    (dIndex: number) => {
      dispatch({
        payload: {
          gap,
          lastSnapPoint,
          loop,
          rawIndex: state.index + dIndex,
          slides,
        },
        type: 'transitionStart',
      });
    },
    [gap, lastSnapPoint, loop, slides, state.index],
  );

  // autoplay
  useEffect(() => {
    if (!autoplay || state.status !== 'idle' || slides.length < 2) {
      return;
    }

    const interval = setInterval(() => {
      moveBy(1);
    }, autoplay);

    return () => {
      clearInterval(interval);
    };
  }, [state.status, autoplay, moveBy, slides.length]);

  useUpdateEffect(() => {
    if (slides.length > 0) {
      dispatch({ payload: { lastSnapPoint, loop, slides }, type: 'resize' });
    }
  }, [loop, lastSnapPoint, slides]);

  return {
    ...options,
    getSlideStyle,
    index: state.index,
    moveBy,
    moveTo,
    trackRef: setTrackRef,
  };
};

export type SwiperStateReturn = ReturnType<typeof useSwiper>;
