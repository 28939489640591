import { useTimer } from './useTimer';

export const useAuthenticateTimer = (start: Date, end: Date) => {
  const { minutes, seconds } = useTimer(start, end);

  return {
    minutes: `0${minutes}`.slice(-2),
    seconds: `0${seconds}`.slice(-2),
  };
};
