import React, { memo } from 'react';
import { keyframes } from 'styled-components';
import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  count?: number;
  lineMargin?: string;
  lastLineSize?: string;
  circle?: boolean;
}

const SkeletonComponent: React.FC<Props> = ({
  className,
  count = 1,
  width = '',
  height = '',
  lineMargin = '',
  lastLineSize = '',
  circle = false,
  ...props
}) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <StyledSkeleton
          key={index}
          $circle={circle}
          $height={height}
          $lastLineSize={lastLineSize}
          $lineMargin={lineMargin}
          $width={width}
          className={className}
          {...props}
        />
      ))}
    </>
  );
};

const bgAnimation = keyframes`
  0% {
    background-position:0% 50%;
  }
  50% {
    background-position:100% 50%;
  }
  100% {
    background-position:0% 50%;
  }
`;

const StyledSkeleton = styled.div<{
  $width: string;
  $height: string;
  $lineMargin: string;
  $lastLineSize: string;
  $circle: boolean;
}>`
  ${({ $width }) =>
    $width &&
    `
    width: ${$width};
    min-width: ${$width};
  `}
  ${({ $height }) => $height && `height: ${$height};`}
  background-color: #f7f8f9;
  background-image: linear-gradient(to right, #e8ebed, #f7f8f9);
  background-size: 400%;
  border-radius: ${({ $circle }) => ($circle ? '50%' : '6px')};
  animation: ${bgAnimation} 1.2s ease infinite;

  ${({ $lineMargin }) =>
    $lineMargin &&
    `
    &:not(:last-child) {
      margin-bottom: ${$lineMargin};
    }
  `}
  ${({ $lastLineSize }) =>
    $lastLineSize &&
    `
    &:last-child {
      width: ${$lastLineSize};
    }
  `}
`;
export const Skeleton = memo(SkeletonComponent);
