import { getShareUrl } from './share.util';

interface KakaoShareImage {
  url?: string;
  // max: 800
  width: number;
  // max: 800
  height: number;
}

interface FeedButton {
  title: string;
  link: KakaoLink;
}

interface KakaoShareOption {
  url?: string;
  image?: KakaoShareImage;
  buttonTitle?: string;
  title?: string;
  description?: string;
  buttons?: FeedButton[];
}

interface KakaoLink {
  webUrl: string;
  mobileWebUrl?: string;
}

interface KakaoContent {
  description: string;
  imageUrl: string;
  imageWidth?: number;
  imageHeight?: number;
  link: KakaoLink;
  title: string;
}

/**
 *
 * @see https://developers.kakao.com/docs/latest/ko/message/message-template
 */
export const shareKakao = (options?: KakaoShareOption) => {
  const kakao = window.Kakao;

  if (!kakao) {
    return;
  }

  const shareUrl = options?.url ?? getShareUrl('kakao');
  const title = options?.title ?? document.title ?? '';
  const description =
    options?.description ??
    document
      .querySelector('meta[name="description"]')
      ?.getAttribute('content') ??
    '';
  const imageUrl =
    options?.image?.url ??
    document
      .querySelector('meta[property="og:image"]')
      ?.getAttribute('content') ??
    '';

  const imageWidth = options?.image?.width;
  const imageHeight = options?.image?.height;

  const content: KakaoContent = {
    description,
    imageUrl,
    link: {
      mobileWebUrl: shareUrl,
      webUrl: shareUrl,
    },
    title,
  };

  if (imageWidth) {
    content.imageWidth = imageWidth;
  }

  if (imageHeight) {
    content.imageHeight = imageHeight;
  }

  kakao.Link.sendDefault({
    buttonTitle: options?.buttonTitle ?? '보러가기',
    content,
    installTalk: true,
    objectType: 'feed',
    buttons: options?.buttons || [],
  });
};
