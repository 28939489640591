import { useEffect, useCallback, useRef } from 'react';
import { useQuery, useQueryClient, QueryKey } from 'react-query';

type Updater<T> = T | ((prev: T | undefined) => T);
type Return<T> = [T, (value: Updater<T>) => void, React.MutableRefObject<T>];

/**
 * useSharedState
 * 공유하는 global state hooks - 전체 컴포넌트에서 key로 state를 공유
 *
 * ### 예제
 * const [state, setState] = useSharedState(false);
 * ...
 *
 * ### 리턴 값
 * [state, setState, stateRef] -> ref는 rerendering 방지용
 */
export function useSharedState<T>(
  cacheKey: QueryKey,
  initialValue: T,
): Return<T> {
  const queryClient = useQueryClient();

  const loadSharedState = (): T =>
    queryClient.getQueryData(cacheKey) ?? initialValue;

  const { data: sharedState } = useQuery(cacheKey, loadSharedState, {
    initialData: loadSharedState,
  });

  const sharedStateRef = useRef<T | undefined>(sharedState);

  const setSharedState = useCallback(
    (next: Updater<T>) => {
      if (typeof next === 'function') {
        queryClient.setQueryData<T>(cacheKey, next);
      } else if (sharedStateRef.current !== next) {
        queryClient.setQueryData<T>(cacheKey, next);
      }
    },
    [queryClient, cacheKey],
  );

  useEffect(() => {
    if (sharedStateRef.current !== sharedState) {
      sharedStateRef.current = sharedState;
    }
  }, [sharedState]);

  useEffect(() => {
    if (sharedStateRef.current === undefined) {
      setSharedState(initialValue);
    }
  }, [initialValue]);

  return [sharedState, setSharedState, sharedStateRef] as Return<T>;
}
