import { createAPIClient } from '~core/createAPIClient';
import { env } from '~core/env';

export const livetvAPI = createAPIClient(
  env.endPoint.livetvApiBaseUrl,
  {
    Accept: 'application/json',
  },
  true,
  { maxRedirects: 0 },
);
