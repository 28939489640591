import { ProfileSchema } from '../../types/data';

export const getFilterUserProfileDataLater = (profile: ProfileSchema) => {
  const data = { ...profile };
  delete data.profileCover;
  delete data.profilePicture;
  return profile;
};

export const settingUserProfileDataLayer = (profile: ProfileSchema) => {
  const { id, birthdate, country, gender } = profile;

  const birthyear = birthdate ? birthdate.slice(0, 4) : null;
  /**
   * local, stage에서는 dataLayer가 없을 수 있습니다.
   * 주로 production용도 (GTM)
   *  */

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'setUserId',
      userId: id,
    });
    window.dataLayer.push({
      event: 'setUserProperties',
      userProperties: {
        birthyear,
        country,
        gender,
      },
    });
    window.dataLayer.push({
      userProfile: getFilterUserProfileDataLater(profile),
    });
  }

  return true;
};
