import React from 'react';
import styled, { useTheme } from 'styled-components';

import { fontSize } from '@styleshare/styled';
import { math } from '@styleshare/styled/polished';

export interface LineClampProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  className?: string;
  fontSize?: keyof typeof fontSize;
  keepHeight?: boolean;
  lines: number;
}

/**
 * <LineClamp />는 텍스트를 최대 몇 줄까지만 보여주고 그 이상은 말줄임표(...)로 생략합니다.
 */
export const LineClamp: React.FC<LineClampProps> = ({
  children,
  as,
  className,
  fontSize = 's16',
  keepHeight,
  lines,
}) => {
  const theme = useTheme();

  // line-height: normal 을 1.2로 간주합니다.
  const height = math(`${theme.fontSize[fontSize]} * 1.2 * ${lines}`);

  return (
    <StyledDiv
      $fontSize={fontSize}
      $height={height}
      $keepHeight={keepHeight}
      $lines={lines}
      as={as}
      className={className}
    >
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{
  $fontSize: keyof typeof fontSize;
  $lines: number;
  $height: string;
  $keepHeight?: boolean;
}>`
  display: -webkit-box;
  height: ${({ $keepHeight, $height }) => ($keepHeight ? $height : undefined)};
  max-height: ${({ $keepHeight, $height }) =>
    $keepHeight ? undefined : $height};
  overflow: hidden;
  font-size: ${({ theme, $fontSize }) => theme.fontSize[$fontSize]};
  text-decoration: none;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $lines }) => $lines};
`;
