import { useEffect, useMemo, useState } from 'react';

type Rect = Pick<
  DOMRectReadOnly,
  'bottom' | 'height' | 'left' | 'right' | 'top' | 'width' | 'x' | 'y'
>;

const defaultRect: Rect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const useResizeObserver = (ref: HTMLElement | null) => {
  const [rect, setRect] = useState<Rect>(defaultRect);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) =>
        setRect(entries[0].contentRect),
      ),
    [],
  );

  useEffect(() => {
    if (!ref) {
      return;
    }

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return rect;
};

export const useResizeObserverChildren = (ref: HTMLElement | null) => {
  const [rects, setRects] = useState<Rect[]>([]);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) =>
        setRects(entries.map((entry) => entry.contentRect)),
      ),
    [],
  );

  useEffect(() => {
    if (!ref || !ref.children) {
      return;
    }

    Array.from(ref.children).forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return rects;
};
