import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: React.ReactNode;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * @see {@link https://zpl.io/b6zEY7d}
 */
export function RoundedIconChip({ className, children, href, onClick }: Props) {
  return (
    <StyledChip
      className={className}
      href={href}
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {children}
    </StyledChip>
  );
}

const StyledChip = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.s16};
  background-color: ${({ theme }) => theme.color.gray100_50};
  border-radius: ${({ theme }) => theme.radius.chip};
`;
