const USER_CONTENTS_URL = process.env.NEXT_PUBLIC_USERCONTENTS_BASE_URL || '';

export function getImageResourcePath(
  id: number | string,
  width: number, //TODO typeof IMAGES_WIDTHS[number]
  height: number | '-' = '-',
): string {
  return `/images/${id}/${width}x${height}`;
}
export const getImageResource = (id: number | string) =>
  `${USER_CONTENTS_URL}/images/${id}`;

interface GetResponsiveImageLoaderProps {
  src: string;
  width: number;
  quality?: number;
}
export const getResponsiveImageLoader = ({
  src,
  width,
}: GetResponsiveImageLoaderProps) =>
  `${USER_CONTENTS_URL}/images/${src}/${width}x-`;

export function staticURL(
  id: number | string,
  width: number,
  height: number | '-' = '-',
): string {
  return `${USER_CONTENTS_URL}${getImageResourcePath(id, width, height)}`;
}

export function staticOriginalURL(id: number | string): string {
  return `${USER_CONTENTS_URL}/images/${id}/original`;
}

export function videoURL(file: string): string {
  return file;
}

export function profileImageUrl(
  id: number | string | undefined | null,
  width: number,
  height: number | '-' = '-',
): string {
  return staticURL(id || '50571263', width, height);
}
