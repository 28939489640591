import { useCallback, useEffect, useState } from 'react';

import {
  useAnalyticsEvent,
  UseAnalyticsEventParams,
} from './useAnalyticsEvent';

export type UseAnalyticsClickEventParams = UseAnalyticsEventParams;

/**
 * 링크나 버튼을 클릭했을 시 이벤트를 넘긴다.
 * @param includeLoggedUserId 이벤트 전달시 사용자 아이디 포함 유무
 *
 * ### 예제
 * ```
 * const { bindAnalyticsTrackRef } = useAnalyticsClickEvent({
 *   event: 'eventName',
 *   parameter: {
 *     next_screen: "test"
 *   }
 * });
 *
 * <Button ref={bindAnalyticsTrackRef} />
 * ```
 *
 * ### 실제 전송 데이터
 * ```
 * {
 *   event: "eventName",
 *   parameter: {
 *     next_screen: "test"
 *   }
 * }
 * ```
 */
export function useAnalyticsClickEvent<T extends HTMLElement | null>(
  params: UseAnalyticsClickEventParams,
) {
  const track = useAnalyticsEvent(params);
  const [current, setCurrent] = useState<T>();

  // dependency로 활용하기 위해 useRef 대신 사용 (ref 변경 추적)
  const bindAnalyticsTrackRef = useCallback(
    (instance: T) => setCurrent(instance),
    [],
  );

  useEffect(() => {
    current?.addEventListener('click', track);

    return () => {
      current?.removeEventListener('click', track);
    };
  }, [current, track]);

  return {
    bindAnalyticsTrackRef,
    refCurrent: current,
    setRefCurrent: setCurrent,
    track,
  };
}
