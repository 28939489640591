import { useEffect, useState } from 'react';

import type { ToastModel } from './toastTypes';

export enum ActionType {
  ADD_TOAST,
  ADD_TOAST_DONE,
  REMOVE_TOAST,
  REMOVE_TOAST_DONE,
  START_PAUSE,
  END_PAUSE,
}

interface ToastState {
  toasts: ToastModel[];
  pausedAt: number | undefined;
}

type ToastAction =
  | {
      type: ActionType.ADD_TOAST;
      toast: ToastModel;
    }
  | {
      type: ActionType.ADD_TOAST_DONE;
      toastId: string;
    }
  | {
      type: ActionType.REMOVE_TOAST;
      toastId: string;
    }
  | {
      type: ActionType.REMOVE_TOAST_DONE;
      toastId: string;
    }
  | {
      type: ActionType.START_PAUSE;
      time: number;
    }
  | {
      type: ActionType.END_PAUSE;
      time: number;
    };

export const reducer = (state: ToastState, action: ToastAction): ToastState => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    case ActionType.ADD_TOAST_DONE:
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id === action.toastId
            ? {
                ...toast,
                status: 'visible',
              }
            : toast,
        ),
      };
    case ActionType.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id === action.toastId
            ? {
                ...toast,
                status: 'disappearing',
              }
            : toast,
        ),
      };
    case ActionType.REMOVE_TOAST_DONE:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.toastId),
      };
    case ActionType.START_PAUSE:
      return {
        ...state,
        pausedAt: action.time,
      };
    case ActionType.END_PAUSE: {
      const diff = action.time - (state.pausedAt || 0);

      return {
        ...state,
        pausedAt: undefined,
        toasts: state.toasts.map((toast) => ({
          ...toast,
          pauseDuration: toast.pauseDuration + diff,
        })),
      };
    }
    default:
      return state;
  }
};

type ToastListener = (state: ToastState) => void;

const listeners: ToastListener[] = [];

let memoryState: ToastState = { pausedAt: undefined, toasts: [] };

export const dispatch = (action: ToastAction) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};

export const useStore = (): ToastState => {
  const [state, setState] = useState<ToastState>(memoryState);

  useEffect(() => {
    listeners.push(setState);

    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return state;
};
