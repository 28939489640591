import { nativeAppFactory } from './native-app.factory';
import type { NativeAppService } from '../../models';

let service: NativeAppService | null = null;

/**
 * 네이티브앱 서비스 객체를 가져온다.
 */
export function getNativeAppService() {
  if (!service) {
    service = nativeAppFactory();
  }
  return service;
}
