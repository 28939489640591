import { sample as _sample } from 'lodash-es';
import { parseToRgb, rgba } from '../polished';
import {
  css,
  CSSObject,
  keyframes,
  SimpleInterpolation,
} from 'styled-components';

import { color } from '../theme';

import { sizes } from './variables';

type SizeKeyType = keyof typeof sizes;
type MediaTemplateFn = (
  first: TemplateStringsArray | CSSObject,
  ...args: SimpleInterpolation[]
) => ReturnType<typeof css>;
interface MediaTemplate {
  up: (key: SizeKeyType) => MediaTemplateFn;
  down: (key: SizeKeyType) => MediaTemplateFn;
}

export const media: MediaTemplate = {
  down:
    (key: SizeKeyType) =>
    (...args) =>
      css`
        @media (max-width: ${sizes[key] - 1}px) {
          ${css(...args)}
        }
      `,
  up:
    (key: SizeKeyType) =>
    (...args) =>
      css`
        @media (min-width: ${sizes[key]}px) {
          ${css(...args)}
        }
      `,
};

type SafeTemplateFn = (
  property: string,
  defautlValue?: number,
) => ReturnType<typeof css>;

interface SafeTemplate {
  left: SafeTemplateFn;
  right: SafeTemplateFn;
  top: SafeTemplateFn;
  bottom: SafeTemplateFn;
}
/**
 * 특정 방향의 env(safe-area-inset-...) 를 반환..?
 * 뭐라고 설명하지
 */
export const safe = ['left', 'right', 'top', 'bottom'].reduce(
  (res, direction) => {
    res[direction as keyof SafeTemplate] = (
      property: string,
      defaultValue = 0,
    ) => css`
      @supports (padding: max(0px)) {
        ${property}: max(constant(safe-area-inset-${direction}), ${defaultValue}px);
        ${property}: max(env(safe-area-inset-${direction}), ${defaultValue}px);
      }
    `;

    return res;
  },
  {} as SafeTemplate,
);

const bgAnimation = keyframes`
  0% {
    background-position:0% 50%;
  }
  50% {
    background-position:100% 50%;
  }
  100% {
    background-position:0% 50%;
  }
`;
export const animatedLinearGradient = () => css`
  background-color: ${({ theme }) => theme.color.gray10};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.color.gray20},
    ${({ theme }) => theme.color.gray10}
  );
  background-size: 400%;
  animation: ${bgAnimation} 1.2s ease infinite;
`;
export const randomPaletteColor = () =>
  _sample([
    color.palette_blue,
    color.palette_green,
    color.palette_lightBlue,
    color.palette_lightGreen,
    color.palette_mint,
    color.palette_orange,
    color.palette_pink,
    color.palette_pinkRed,
    color.palette_purple,
    color.palette_red,
    color.palette_yellow,
  ]) as string;

export const multiplayOpacity = (amount: number, color: string): string => {
  if (color === 'transparent') {
    return color;
  }

  const parsedColor = parseToRgb(color);
  const alpha = 'alpha' in parsedColor ? parsedColor.alpha : 1;
  const colorWithAlpha = {
    ...parsedColor,
    alpha: alpha * amount,
  };

  return rgba(colorWithAlpha);
};
