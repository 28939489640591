import { BaseNativeAppService } from './BaseNativeAppService';
import {
  AnalyticsEventParams,
  ChangeSearchResultTabQuery,
  CollectStyleParams,
  HandlerResultType,
  NativeAppService,
  SelectServiceHomeAsDefaultTabParams,
  SetShareInformationParams,
  UpdateArticleLikeParams,
} from '../../models';

interface IOSMessageExecutor {
  postMessage(payload?: string | number | Record<string, any>): void;
}

interface IOSMessageHandlers {
  [name: string]: IOSMessageExecutor;
}

interface IOSWebkit {
  messageHandlers: IOSMessageHandlers;
}

interface IOSWebkitWindow {
  webkit: IOSWebkit;
}

export class IOSAppService
  extends BaseNativeAppService
  implements NativeAppService
{
  /**
   * IOS 전용 네이티브 앱 기능을 제공하는 서비스.
   */
  constructor() {
    super();
  }

  changeSearchResultTab(params: ChangeSearchResultTabQuery) {
    this.messageHandlers('changeSearchResultTab', params);
  }
  collectStyle(params: CollectStyleParams) {
    this.messageHandlers('collectStyle', params);
  }
  updateArticleCommentPreview() {
    this.messageHandlers('updateArticleCommentPreview');
  }
  updateArticleLike(params: UpdateArticleLikeParams) {
    this.messageHandlers('updateArticleLike', params);
  }
  stopLoading() {
    if (this.messageHandlers('stopLoading', {}) !== HandlerResultType.OK) {
      // setUrlToTemporaryIframe('stsh-web://stop-loading', 'iframe_stopLoading');
    }
  }
  sendAnalytics({ event, parameters }: AnalyticsEventParams) {
    const result = this.messageHandlers('analytics', { event, parameters });

    if (result === HandlerResultType.NOT_FOUND) {
      const qs = new URLSearchParams({ event, ...parameters }).toString();
      window.location.href = 'stsh://analytics?' + qs;
    }
  }
  selectServiceHomeAsDefaultTab(params: SelectServiceHomeAsDefaultTabParams) {
    this.messageHandlers('selectServiceHomeAsDefaultTab', params);
  }
  setShareInformation(params: SetShareInformationParams) {
    this.messageHandlers('setShareInformation', params);
  }
  destroy() {
    // 서비스가 사라질 때 후속 작업이 필요하다면 채워주세요.
  }
  messageHandlers<T extends Record<string, any> | string | number>(
    name: string,
    payload?: T,
  ) {
    try {
      const win = window as unknown as IOSWebkitWindow;

      if (
        !win.webkit ||
        !win.webkit.messageHandlers ||
        !win.webkit.messageHandlers[name]
      ) {
        return HandlerResultType.NOT_FOUND;
      }

      win.webkit.messageHandlers[name].postMessage(payload);

      return HandlerResultType.OK;
    } catch (error) {
      const err = error as Error;

      if (err && err.message) {
        // errorReport(err);
      }

      return HandlerResultType.EXCEPTED;
    }
  }
}
