import Head from 'next/head';

const description =
  'No.1 패션・뷰티 SNS 기반 쇼핑 플랫폼. 지금 유행하는 트렌드 아이템, 디자이너 브랜드 상품 판매';
export const DefaultHead = () => {
  return (
    <Head>
      <meta content="IE=Edge" httpEquiv="X-UA-Compatible" />
      <meta content="StyleShare" name="application-name" />
      <meta content="telephone=no" name="format-detection" />
      <meta content="light" name="supported-color-schemes" />
      <meta content="light" name="color-scheme" />
      <meta key="description" content={description} name="description" />
      <meta
        key="og:description"
        content={description}
        property="og:description"
      />
      <meta key="twitter:card" content="app" name="twitter:card" />
      <meta key="twitter:site" content="@StyleShare_twt" name="twitter:site" />
      <meta key="og:type" content="website" property="og:type" />
      <link href="https://usercontents-d.styleshare.io" rel="preconnect" />
      <link href="https://usercontents-d.styleshare.io" rel="dns-prefetch" />
    </Head>
  );
};
