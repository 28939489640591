import styled from 'styled-components';
import LazyLoad from 'vanilla-lazyload';

import { env } from '~core';
import useIsomorphicLayoutEffect from '~modules/@shared/hooks/useIsomorphicLayoutEffect';
import { staticURL } from '~modules/@shared/utils';

import { AspectRatio } from '..';

interface VideoImageProps {
  alt: string;
  imageId: number | string;
}
const RATIOS = [1, 2, 3];

export const VideoImage = ({ alt, imageId }: VideoImageProps) => {
  useIsomorphicLayoutEffect(() => {
    const lazyLoad = !env.isServer
      ? new LazyLoad({
          elements_selector: '[loading="lazy"]',
          use_native: true,
        })
      : null;
    lazyLoad?.update();
  }, []);
  const srcRatio = RATIOS[RATIOS.length - 1];
  const width = 200;
  const height = 200;
  const src = staticURL(imageId, width * srcRatio, height * srcRatio);

  const srcSet = RATIOS.map(
    (ratio) => `${staticURL(imageId, width * ratio, height * ratio)} ${ratio}x`,
  ).join(', ');

  return (
    <StyledAspectRatio heightRatio={247} widthRatio={200}>
      <StyledImg
        alt={alt}
        data-src={src}
        data-srcset={srcSet}
        loading={'lazy'}
      />
    </StyledAspectRatio>
  );
};

const StyledAspectRatio = styled(AspectRatio)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray10};
  mask-image: -webkit-radial-gradient(white, black);
`;

const StyledImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
