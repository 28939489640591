import React from 'react';
import styled, { css, Keyframes, keyframes } from 'styled-components';

import { TRANSITION_DURATION } from './toastConstant';
import type { ToastStatus } from './toastTypes';

interface Props {
  status: ToastStatus;
  children?: React.ReactNode;
  className?: string;
  marginBottom?: number;
}

export const ToastBase = ({
  status,
  children,
  className,
  marginBottom,
}: Props) => {
  return (
    <StyledDiv
      $marginBottom={marginBottom}
      $status={status}
      aria-live="polite"
      className={className}
      role="status"
    >
      {children}
    </StyledDiv>
  );
};

const animations: Record<ToastStatus, Keyframes | undefined> = {
  appearing: keyframes`
    from {
      transform: translateY(calc(100% + 12px));
    }

    to {
      transform: translateY(0px);
    }
  `,
  disappearing: keyframes`
    from {
      transform: translateY(0px);
    }

    to {
      transform: translateY(calc(100% + 12px));
    }
  `,
  visible: undefined,
};
interface StyledDivProps {
  $status: ToastStatus;
  $marginBottom?: number;
}
const StyledDiv = styled.div<StyledDivProps>`
  z-index: ${({ theme }) => theme.zIndex.modal};
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-items: center;
  max-width: 480px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 12}px;
  background-color: ${({ theme }) => theme.color.gray90_97};
  border-radius: ${({ theme }) => theme.radius.box};
  animation: ${({ $status }) =>
    css`
      ${animations[$status]} ${TRANSITION_DURATION}ms ease-out both
    `};
  pointer-events: initial;
`;
