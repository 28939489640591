import React from 'react';
import styled from 'styled-components';

import { ToastBase } from './ToastBase';
import type { ToastStatus } from './toastTypes';
import { LineClamp } from '../LineClamp';

interface Props {
  status: ToastStatus;
  button?: string;
  href?: string;
  children?: React.ReactNode;
  className?: string;
  marginBottom?: number;
  onButtonClick?: React.MouseEventHandler;
}

export const ToastSnackbar = ({
  status,
  button,
  href,
  children,
  className,
  marginBottom,
  onButtonClick,
}: Props) => {
  const buttonText = button ?? '닫기';

  return (
    <StyledToastBase
      className={className}
      marginBottom={marginBottom}
      status={status}
    >
      <StyledLineClamp fontSize="s14" lines={2}>
        {children}
      </StyledLineClamp>
      {href ? (
        <StyledButton as="a" href={href} onClick={onButtonClick}>
          {buttonText}
        </StyledButton>
      ) : (
        <StyledButton onClick={onButtonClick}>{buttonText}</StyledButton>
      )}
    </StyledToastBase>
  );
};

const StyledToastBase = styled(ToastBase)`
  width: 100%;
  height: 54px;
`;

const StyledLineClamp = styled(LineClamp)`
  flex: 1;
  padding-left: 12px;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.s14};
`;

const StyledButton = styled.button`
  padding: 12px;
  color: ${({ theme }) => theme.color.gray50};
  font-size: ${({ theme }) => theme.fontSize.s14};
`;
