/**
 * user-agent 정보를 이용하여 단순 모바일인지 여부를 확인한다.
 *
 * 모바일 기종: 안드로이드 계열 전 기종, ios 를 사용하는 모든 기종 (아이폰, 아이패드, 아이팟 등)
 */
export const isMobile = (userAgent?: string) =>
  /mobi/gi.test(userAgent ?? globalThis.navigator?.userAgent);

/**
 * user-agent 정보를 이용하여 native app 여부를 판단한다.
 */
export const isNativeApp = (userAgent?: string) =>
  /styleshare/gi.test(userAgent ?? globalThis.navigator?.userAgent);

/**
 * user-agent 정보를 이용하여 ios 여부를 판단한다.
 */
export const isIOS = (userAgent?: string) =>
  /ipad|iphone|ipod/gi.test(userAgent ?? globalThis.navigator?.userAgent);

/**
 * user-agent 정보를 이용하여 ios/safari 여부를 판단한다.
 */
export const isSafari = (userAgent?: string) =>
  /safari/gi.test(userAgent ?? globalThis.navigator?.userAgent) &&
  !/chrome/gi.test(userAgent ?? globalThis.navigator?.userAgent);
