import React, { RefObject, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Dialog, DialogBackdrop, DialogStateReturn } from 'reakit/Dialog';
import styled from 'styled-components';

import { Cancel } from '@styleshare/react-icons/line';

import { LineClamp } from '../LineClamp';

interface Props extends DialogStateReturn {
  label?: string;
  childrenScrollRef?: RefObject<HTMLElement> | null;
  children?: React.ReactNode;
}

export const Modal = ({
  label,
  childrenScrollRef,
  children,
  ...dialog
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  // iOS에서 콘텐츠 스크롤이 안되는 문제를 우회함
  useEffect(() => {
    const element = scrollRef.current;
    const scrollElement = childrenScrollRef && childrenScrollRef.current;
    if (!element) {
      return;
    }

    if (dialog.visible) {
      disableBodyScroll(element);
      scrollElement && disableBodyScroll(scrollElement);
    }

    return () => {
      enableBodyScroll(element);
      scrollElement && enableBodyScroll(scrollElement);
    };
  }, [dialog.visible, childrenScrollRef]);

  return (
    <StyledDialogBackdrop {...dialog}>
      <StyledDialog {...dialog} aria-label={label}>
        <ModalHeader>
          {label && <LineClamp lines={1}>{label}</LineClamp>}
          <StyledButton aria-label="닫기" onClick={dialog.hide}>
            <StyledCancel aria-hidden />
          </StyledButton>
        </ModalHeader>
        <ModalBody ref={scrollRef}>{children}</ModalBody>
      </StyledDialog>
    </StyledDialogBackdrop>
  );
};

const StyledDialogBackdrop = styled(DialogBackdrop)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.black};
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    content: '';
  }

  &[data-enter] {
    &::before {
      opacity: 0.3;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: calc(100% - 360px);
  margin: 16px;
  padding: 8px 20px 12px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.box};
  box-shadow: 0 5px 12px ${({ theme }) => theme.color.black_12};
  transform: translate3d(0, 20%, 0);
  opacity: 0;
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;

  &[data-enter] {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.gray100};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.s24};
`;

const ModalBody = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
`;

const StyledButton = styled.button`
  display: block;
  margin-left: auto;
  padding: 0;
`;

const StyledCancel = styled(Cancel)`
  display: block;
  width: 24px;
  height: 24px;
`;
