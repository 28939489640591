import React, { useEffect } from 'react';
import styled from 'styled-components';
import LazyLoad from 'vanilla-lazyload';

import { env } from '~core';

import { AspectRatio } from '../AspectRatio';

export interface ImageProps {
  className?: string;
  /**
   * lazy load를 사용할지
   */
  lazy?: boolean;
  /**
   * img 태그에 전달됩니다.
   */
  alt: string;
  /**
   * img 태그에 전달됩니다.
   */
  src: string;
  /**
   * img 태그에 전달됩니다.
   */
  srcSet?: string;
  /**
   * img 태그에 전달됩니다.
   */
  sizes?: string;
  /**
   * px 값, img 태그에 전달됩니다.
   */
  width: number;
  /**
   * px 값, img 태그에 전달됩니다.
   */
  height: number;
}

const lazyLoad = !env.isServer
  ? new LazyLoad({
      elements_selector: '[loading="lazy"]',
      use_native: true,
    })
  : null;

/**
 * <Image />는 img 엘리먼트의 wrapper 컴포넌트입니다.
 * Lazy loading을 지원합니다. 이미지를 불러오는 중에는 gray10 배경색을 보여줍니다.
 */
export const Image = ({
  alt,
  height,
  lazy,
  sizes,
  src,
  srcSet,
  width,
  className,
}: ImageProps) => {
  useEffect(() => {
    if (lazy) {
      lazyLoad?.update();
    }
  }, [lazy]);

  return (
    <StyledAspectRatio
      className={className}
      heightRatio={height}
      widthRatio={width}
    >
      <StyledImg
        alt={alt}
        data-sizes={lazy ? sizes : undefined}
        data-src={lazy ? src : undefined}
        data-srcset={lazy ? srcSet : undefined}
        height={height}
        loading={lazy ? 'lazy' : undefined}
        sizes={lazy ? undefined : sizes}
        src={lazy ? undefined : src}
        srcSet={lazy ? undefined : srcSet}
        width={width}
      />
    </StyledAspectRatio>
  );
};

const StyledAspectRatio = styled(AspectRatio)`
  overflow: hidden;
  /**
   * 이미지가 로딩 중일 때 이미지 영역에 배경색을 보여줍니다.
   */
  background-color: ${({ theme }) => theme.color.gray10};
  /**
   * Safari에서 border-radius + overflow: hidden 이슈를 해결합니다.
   * https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
   */
  mask-image: -webkit-radial-gradient(white, black);
`;

const StyledImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  /**
   * 투명한 배경의 PNG 이미지라면 배경색을 흰색으로 채우게 합니다.
   */
  background-color: white;

  &:not([src]) {
    visibility: hidden;
  }

  &[loading='lazy'] {
    min-width: 1px;
    min-height: 1px;
    opacity: 0;

    &:not(.initial) {
      transition: opacity 250ms ease-out;
    }

    &.initial,
    &.loaded,
    &.error {
      opacity: 1;
    }
  }
`;
