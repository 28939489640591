import { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import styled, { css } from 'styled-components';

import { getResponsiveImageLoader } from '../utils';

interface ResponsiveImageProps
  extends Omit<
    ImageProps,
    'height' | 'width' | 'loader' | 'onLoadingComplete' | 'src' | 'layout'
  > {
  imageId: string | number;
  fixedRatio?: number;
  width?: number;
  height?: number;
  loader?: ImageProps['loader'];
}
export const ResponsiveImage = ({
  imageId,
  fixedRatio,
  width,
  height,
  loader,
  ...props
}: ResponsiveImageProps) => {
  const [{ naturalHeight, naturalWidth }, setNaturalSize] = useState({
    naturalHeight: height ?? 0,
    naturalWidth: width ?? 0,
  });

  const ImageComp = (
    <Image
      {...props}
      height={naturalHeight}
      layout="responsive"
      loader={loader ?? getResponsiveImageLoader}
      src={`${imageId}`}
      width={naturalWidth}
      onLoadingComplete={setNaturalSize}
    />
  );

  if (fixedRatio) {
    return (
      <StyledWrap $fixedRatio={fixedRatio}>
        <div>{ImageComp}</div>
      </StyledWrap>
    );
  }
  return ImageComp;
};

const StyledWrap = styled.div<{ $fixedRatio: number }>(
  ({ $fixedRatio }) => css`
    position: relative;
    padding-top: ${$fixedRatio}%;
    overflow: hidden;
    > div {
      position: absolute;
      inset: 0px;
    }
  `,
);
