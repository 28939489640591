import React, { FC, SyntheticEvent } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Like as LineLike } from '@styleshare/react-icons/line';
import { Like as SolidLike } from '@styleshare/react-icons/solid';

interface Props {
  iconSize: number;
  isLiked: boolean;
  onClick(e: SyntheticEvent): void;
}
export const AnimationLike: FC<Props> = ({
  iconSize,
  isLiked,
  children,
  onClick,
  ...props
}) => {
  return (
    <StyledButton {...props} onClick={onClick}>
      <StyledIconWrap $iconSize={iconSize}>
        <StyledSolidLike $iconSize={iconSize} $isLiked={isLiked} />
        <StyledLineLike $iconSize={iconSize} $isLiked={isLiked} />
      </StyledIconWrap>
      {children}
    </StyledButton>
  );
};

const FadeInAnimation = (iconSize: number) => keyframes`
  0% {
    width: ${iconSize - 6}px;
    height: ${iconSize - 6}px;
  }
  65% {
    width: ${iconSize + 5}px;
    height: ${iconSize + 5}px;
  }
  100% {
    width: ${iconSize}px;
    height: ${iconSize}px;
  }
`;
const LineFadeInAnimation = (iconSize: number) => keyframes`
  0% {
    width: ${iconSize - 6}px;
    height: ${iconSize - 6}px;
  }
  100% {
    width: ${iconSize}px;
    height: ${iconSize}px;
  }
`;

const StyledButton = styled.button`
  padding: 0;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
const StyledIconWrap = styled.div<{ $iconSize: number }>`
  position: relative;
  display: inline-flex;
  width: ${({ $iconSize }) => $iconSize + 6}px;
  height: ${({ $iconSize }) => $iconSize + 6}px;
  margin-top: -3px;
  vertical-align: middle;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ $iconSize }) => $iconSize}px;
    height: ${({ $iconSize }) => $iconSize}px;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12));
  }
`;

const StyledSolidLike = styled(SolidLike)<{
  $iconSize: number;
  $isLiked: boolean;
}>`
  color: ${({ theme }) => theme.color.palette_pinkRed} !important;

  ${({ $isLiked, $iconSize }) =>
    $isLiked
      ? css`
          animation: ${FadeInAnimation($iconSize)} 0.07s linear forwards;
        `
      : css`
          opacity: 0;
        `}
`;
const StyledLineLike = styled(LineLike)<{
  $iconSize: number;
  $isLiked: boolean;
}>`
  ${({ $isLiked, $iconSize }) =>
    $isLiked
      ? css`
          opacity: 0;
        `
      : css`
          animation: ${LineFadeInAnimation($iconSize)} 0.07s linear forwards;
        `}
`;

AnimationLike.displayName = 'AnimationLike';
