import React from 'react';
import styled from 'styled-components';

import { math } from '@styleshare/styled/polished';

interface Props {
  className?: string;
}

export function EventCardSkeleton({ className }: Props) {
  return (
    <StyledDiv className={className}>
      <div className="image" />
      <div className="text-box">
        <div />
        <div />
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div.attrs(({ theme }) => ({
  $height: math(`${theme.fontSize.s13} * 1.16`),
}))`
  .image {
    padding-bottom: calc(2 / 3 * 100%);
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.gray10};
    border-radius: ${({ theme }) => theme.radius.box};
  }

  .text-box {
    margin: 7px 0 8px;

    & > div {
      height: ${({ $height }) => $height};
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.gray10};
      border-radius: ${({ theme }) => theme.radius.text_skeleton};

      &:last-child {
        width: 60%;
        margin-top: 2px;
      }
    }
  }
`;
