import { baseAPI } from '~core';

export interface GetMeProfileResponse {
  admin: boolean;
  bio: string;
  birthdate: string;
  collectionsCount: number;
  country: string;
  createdAt: string;
  email: string;
  facebookPublish: boolean;
  feedGroup: null; // TODO: check type
  followersCount: number;
  followingsCount: number;
  gender: 'female' | 'male';
  id: number;
  isDormant?: boolean;
  isOfficial: boolean;
  likesCount: number;
  linkEnabled: boolean;
  locale: string;
  nickname: string;
  occupation: string;
  profileCoverId: number | null; // TODO: check type
  profilePictureId: number | null; // TODO: check type
  uploadedStylesCount: number;
  username: string;
  website: string;
}

export function getMeProfile() {
  return baseAPI.get<GetMeProfileResponse>('/me/profile');
}

export function getMeProfileWithCookies(cookies?: string) {
  return baseAPI.get<GetMeProfileResponse>('/me/profile', undefined, cookies);
}

export function getMePhoneNumberVerified() {
  return baseAPI.get<{ data: boolean }>('/me/phone-number-verified');
}
